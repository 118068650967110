import React, { useState } from 'react'
import { Box, Toolbar, Chip, Badge, Button } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import ClearIcon from '@mui/icons-material/Clear'
import { useTranslation } from 'react-i18next'
import { StatusCount, EEntryType } from '../../../data/types'

type Props = {
  statusCount: StatusCount | null
  setOpenSearchFilters: (open: boolean) => void
  resetFilters: () => void
  onBadgeClick: (entryType: EEntryType[]) => void
}

const CountStatuses = ({ statusCount, setOpenSearchFilters, resetFilters, onBadgeClick }: Props) => {
  const { t } = useTranslation()
  const [activeFilter, setActiveFilter] = useState<EEntryType[]>([])

  const handleBadgeClick = (entryType: EEntryType) => {
    setActiveFilter((prev) => {
      if (entryType && !prev.includes(entryType) && prev.length < 4) {
        const newFilter = [...prev, entryType]
        onBadgeClick(newFilter)
        return newFilter
      }
      return prev
    })
  }

  return (
    <Toolbar sx={{ ml: '5%', justifyContent: 'space-between', mr: '5%' }}>
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          setOpenSearchFilters(true)
        }}
        sx={{ backgroundColor: activeFilter ? 'primary.main' : 'default' }}
      >
        <FilterAltIcon fontSize="medium" sx={{ color: 'white' }} />
      </Button>
      <Badge
        badgeContent={statusCount?.creditCount.toString() ?? '0'}
        color="primary"
        max={10000}
        onClick={() => handleBadgeClick(EEntryType.Credit)}
      >
        <Chip
          label={t('statusCounts.credit')}
          sx={{ minWidth: 200, backgroundColor: activeFilter.includes(EEntryType.Credit) ? 'primary.main' : 'default' }}
          variant="outlined"
        />
      </Badge>
      <Box sx={{ m: 2 }} />
      <Badge
        badgeContent={statusCount?.documentsAndIncomesCount.toString() ?? '0'}
        color="primary"
        max={10000}
        onClick={() => handleBadgeClick(EEntryType.DocumentsAndIncomes)}
      >
        <Chip
          label={t('statusCounts.documentsAndIncomes')}
          sx={{
            minWidth: 200,
            backgroundColor: activeFilter.includes(EEntryType.DocumentsAndIncomes) ? 'primary.main' : 'default',
          }}
          variant="outlined"
        />
      </Badge>
      <Box sx={{ m: 2 }} />
      <Badge
        badgeContent={statusCount?.financingCount.toString() ?? '0'}
        color="primary"
        max={10000}
        onClick={() => handleBadgeClick(EEntryType.Financing)}
      >
        <Chip
          label={t('statusCounts.financing')}
          sx={{
            minWidth: 200,
            backgroundColor: activeFilter.includes(EEntryType.Financing) ? 'primary.main' : 'default',
          }}
          variant="outlined"
        />
      </Badge>
      <Box sx={{ m: 2 }} />
      <Badge
        badgeContent={statusCount?.fundingCount.toString() ?? '0'}
        color="primary"
        max={10000}
        onClick={() => handleBadgeClick(EEntryType.Funding)}
      >
        <Chip
          label={t('statusCounts.funding')}
          sx={{
            minWidth: 200,
            backgroundColor: activeFilter.includes(EEntryType.Funding) ? 'primary.main' : 'default',
          }}
          variant="outlined"
        />
      </Badge>
      <Box sx={{ m: 2 }} />
      <Badge badgeContent={statusCount?.total.toString() ?? '0'} color="primary" max={100000}>
        <Chip label={t('statusCounts.total')} sx={{ minWidth: 200 }} />
      </Badge>
      {activeFilter.length > 0 && (
        <Button
          variant="outlined"
          startIcon={<ClearIcon fontSize="small" color="error" />}
          onClick={() => {
            resetFilters()
            setActiveFilter([])
          }}
        />
      )}
    </Toolbar>
  )
}

export default CountStatuses
