import { InputAdornment, Stack } from '@mui/material'
import { DatePicker, InputTextField } from '@src/components'
import { EProductsInsurance } from '@src/data/types'
import { ProductsWorksheetDto } from '@src/data/types/ProductsWorksheetSchema'
import { t } from 'i18next'
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'

interface Props {
  register: UseFormRegister<ProductsWorksheetDto>
  insurance: EProductsInsurance
  control: Control<ProductsWorksheetDto>
  errors: FieldErrors<ProductsWorksheetDto>
}

const ProductsInsuranceDetails = ({ insurance: name, register, control, errors }: Props) => {
  return (
    <Stack spacing={2} mt={2}>
      <InputTextField
        label={t('worksheet.policy')}
        fullWidth
        {...register(`${name}.police`)}
        error={errors[name]?.police}
      />
      <InputTextField
        label={t('worksheet.term')}
        error={errors[name]?.term}
        {...register(`${name}.term`)}
        InputProps={{
          endAdornment: <InputAdornment position="end">{t('worksheet.month')}</InputAdornment>,
        }}
      />
      <InputTextField
        label={t('worksheet.premium')}
        error={errors[name]?.amount}
        {...register(`${name}.amount`)}
        InputProps={{
          endAdornment: <InputAdornment position="end">$</InputAdornment>,
        }}
      />
      <DatePicker
        name={`${name}.coverageStartDate`}
        error={errors[name]?.coverageStartDate}
        label={t('worksheet.coverageStartDate')}
        disablePast
        minDate={new Date()}
        control={control}
      />
    </Stack>
  )
}

export default ProductsInsuranceDetails
