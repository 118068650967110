import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonGroup, Divider, Paper, Stack, Tooltip, Typography } from '@mui/material'
import {
  Print as PrintIcon,
  PendingActions as PendingActionsIcon,
  CheckCircle as CheckCircleIcon,
  ContentPasteGo,
  Cancel as CancelIcon,
  ReportProblem,
} from '@mui/icons-material'
import {
  CreditApplication,
  User,
  BaseWorksheet,
  ECreditApplicationStep,
  ETasksStatus,
  EDocumentStatus,
  EElectronicSignatureStatus,
  EFinancingProgram,
} from '../../../data/types'
import { formatDate } from '../../../services/Formatter'
import { creditSelectors } from '../../../data/store/CreditApplication'
import { useAppSelector } from '../../../data/store'
import { documentSelectors } from '../../../data/store/Document'

type Props = {
  handleExpenseClicked: (data: 'applicant' | 'coApplicant') => void
  onClickGenerateCvt: () => void
  onClickPrintCvt: () => void
  onClickElectronicSign: () => void
  onClickBankAccount: () => void
  creditApplication: CreditApplication
  worksheet: BaseWorksheet | null
  electronicSignatureStatus: EElectronicSignatureStatus | null
  user: User | null
  editDisabled: boolean
  disableResendLinkButton: boolean
}

const FinancingContractSection = ({
  handleExpenseClicked,
  onClickGenerateCvt,
  onClickPrintCvt,
  onClickElectronicSign,
  onClickBankAccount,
  creditApplication,
  worksheet,
  electronicSignatureStatus,
  user,
  editDisabled,
  disableResendLinkButton,
}: Props) => {
  const { t } = useTranslation()

  const getIcon = (isCompleted: boolean) => {
    return isCompleted ? (
      <CheckCircleIcon fontSize="small" color="success" />
    ) : (
      <PendingActionsIcon fontSize="small" color="warning" />
    )
  }

  const financingProgramConfig = useAppSelector(creditSelectors.getFinancingProgramConfigs)

  const getButtonText = (isCompleted: boolean | null | undefined) => {
    return isCompleted ? '' : t('financeContract.toBeCompleted')
  }

  const isAbleToPrintCvt = useAppSelector(creditSelectors.isAbleToPrintCvt)
  const applicantLaw134IsCompleted = useAppSelector(creditSelectors.getApplicantLaw134IsCompleted)
  const coApplicantLaw134IsCompleted = useAppSelector(creditSelectors.getCoApplicantLaw134IsCompleted)
  const applicantIsFromQc = useAppSelector(creditSelectors.getApplicantIsFromQc)
  const coApplicantIsFromQc = useAppSelector(creditSelectors.getCoApplicantIsFromQc)
  const taskList = useAppSelector(creditSelectors.getAllTask)
  const documentRequestedList = useAppSelector(documentSelectors.getDocumentRequestedList)
  const documentList = useAppSelector(documentSelectors.getDocumentList)
  const hasReceivedCvtDocument = useAppSelector(documentSelectors.hasReceivedCvtDocument)

  const steps = [ECreditApplicationStep.CVT, ECreditApplicationStep.Financing, ECreditApplicationStep.Credit]

  const hasTaskPending = taskList.some(
    (item) =>
      item.requiredBeforeStep &&
      (steps as string[]).includes(item.requiredBeforeStep) &&
      item.status === ETasksStatus.ToDo,
  )

  const hasDocumentPending = documentRequestedList.some(
    (documentRequested) =>
      !documentList.some(
        (document) =>
          document.status === EDocumentStatus.Approved &&
          documentRequested.typeId === document.typeId &&
          documentRequested.applicantType === document.applicantType,
      ) &&
      documentRequested.requiredBeforeStep &&
      (steps as string[]).includes(documentRequested.requiredBeforeStep),
  )

  const getElectronicSignatureStatus = () => {
    switch (electronicSignatureStatus) {
      case EElectronicSignatureStatus.Pending:
      case EElectronicSignatureStatus.CreateContract:
      case EElectronicSignatureStatus.StartSignatureProject:
      case EElectronicSignatureStatus.AwaitingSignature:
        return getIcon(false)
      case EElectronicSignatureStatus.Completed:
        return getIcon(true)
      case EElectronicSignatureStatus.Cancel:
      case EElectronicSignatureStatus.CancelSignatureProject:
        return <CancelIcon fontSize="small" color="error" />
      default:
        return <ReportProblem fontSize="small" color="warning" />
    }
  }

  const isElectronicSignatureDisabled = () => {
    return (
      !electronicSignatureStatus ||
      electronicSignatureStatus === EElectronicSignatureStatus.Pending ||
      electronicSignatureStatus === EElectronicSignatureStatus.Completed ||
      electronicSignatureStatus === EElectronicSignatureStatus.CancelSignatureProject ||
      electronicSignatureStatus === EElectronicSignatureStatus.Cancel ||
      editDisabled ||
      hasReceivedCvtDocument
    )
  }

  return (
    <Paper elevation={3} sx={{ borderRadius: 2, p: 2, mt: 3, mb: 3 }}>
      <Stack alignItems="left" justifyContent="space-between">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" component="span" gutterBottom>
            {t('financeContract.financeContract')}
          </Typography>
          <Stack direction="row" spacing={2}>
            {financingProgramConfig.useConsigno && creditApplication?.cvtNumber && (
              <ButtonGroup>
                <Tooltip
                  title={`${t('electronicSignature.status')}: ${
                    electronicSignatureStatus ? t(`electronicSignature.${electronicSignatureStatus}`) : 'N/A'
                  }`}
                  placement="top"
                >
                  <span>
                    <Button
                      endIcon={getElectronicSignatureStatus()}
                      variant="outlined"
                      disabled={isElectronicSignatureDisabled() || disableResendLinkButton}
                      onClick={onClickElectronicSign}
                    >
                      {t('electronicSignature.sendLink')}
                    </Button>
                  </span>
                </Tooltip>
              </ButtonGroup>
            )}
            <Button
              variant="outlined"
              disabled={editDisabled || !user?.rights.canEditCreditApp}
              onClick={onClickBankAccount}
              endIcon={getIcon(creditApplication.paymentBankAccount !== null)}
            >
              {t('bankAccount.title')}
            </Button>
            {applicantIsFromQc && (
              <Button
                variant="outlined"
                endIcon={getIcon(applicantLaw134IsCompleted ?? false)}
                onClick={() => handleExpenseClicked('applicant')}
                disabled={editDisabled || !user?.rights.canEditCreditApp}
              >
                {t('financeContract.law134Applicant')} {getButtonText(applicantLaw134IsCompleted)}
              </Button>
            )}
            {creditApplication.coApplicant !== null && coApplicantIsFromQc && (
              <Button
                variant="outlined"
                onClick={() => handleExpenseClicked('coApplicant')}
                endIcon={getIcon(coApplicantLaw134IsCompleted)}
                disabled={editDisabled || !user?.rights.canEditCreditApp}
              >
                {t('financeContract.law134CoApplicant')} : {getButtonText(coApplicantLaw134IsCompleted)}
              </Button>
            )}
          </Stack>
        </Stack>
        {!isAbleToPrintCvt && (
          <Typography color="error" component="div" gutterBottom>
            {t('financeContract.errorMessage')}
          </Typography>
        )}
      </Stack>

      {isAbleToPrintCvt && (
        <>
          <Divider flexItem sx={{ height: 15, alignSelf: 'center' }} />

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            alignItems="top"
            marginTop={1}
            justifyContent="space-evenly"
            divider={<Divider orientation="vertical" flexItem />}
          >
            {!creditApplication?.cvtNumber && (
              <Button
                endIcon={
                  <ContentPasteGo fontSize="large" color={user?.rights.canEditCreditApp ? 'primary' : 'inherit'} />
                }
                color="inherit"
                sx={{ pl: 0 }}
                onClick={onClickGenerateCvt}
                disabled={
                  !user?.rights.canEditCreditApp ||
                  hasTaskPending ||
                  hasDocumentPending ||
                  creditApplication.financingProgramId === EFinancingProgram.Personal ||
                  editDisabled
                }
              >
                {t('financeContract.generateCvt')}
              </Button>
            )}
            {creditApplication?.cvtNumber && (
              <Button
                endIcon={<PrintIcon fontSize="large" color="primary" />}
                color="inherit"
                sx={{ pl: 0 }}
                onClick={onClickPrintCvt}
              >
                CVT : #{creditApplication?.cvtNumber}
              </Button>
            )}
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.deliveryOn')} : {formatDate(worksheet?.deliveryOn)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.firstPaymentOn')} : {formatDate(worksheet?.firstPaymentOn)}
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
              {t('worksheet.lastPaymentDate')} : {formatDate(worksheet?.lastPaymentOn)}
            </Typography>
          </Stack>
        </>
      )}
    </Paper>
  )
}

export default FinancingContractSection
