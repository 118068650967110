/* eslint-disable func-names */
import { get } from 'lodash-es'
import { AnySchema, NumberSchema, StringSchema } from 'yup'
import { AddressSchema, FullAddressSchema } from './AddressSchema'
import yupExtInt from './common/SchemaTypes'
import * as yup from './common/yup-extended'
import {
  Constants,
  defaultProgramConfig,
  EBeneficiaryType,
  EBeneficiaryTypeList,
  ECreditApplicationStatus,
  ECreditApplicationStatusList,
  EFinancingProgram,
  EFinancingProgramList,
  EHomeStatus,
  EHomeStatusList,
  ELoanPurpose,
  ELoanPurposeList,
  EOriginSystemId,
  EOriginSystemIdList,
  EOtherIncomeType,
  EPaymentPlan,
  ERelationToApplicant,
  ERelationToApplicantList,
} from './constants'
import {
  ApplicantCreditDecision,
  CreditDecision,
  CreditDecisionOverrides,
  NormCreditDecision,
} from './CreditDecisionSchema'
import { ExistingCustomerReference } from './CustomerReferenceSchema'
import { HardHitReport } from './HardHitReport'
import { PrequalificationDecision } from './PrequalificationDecision'
import { Refinancing } from './RefinancingSchema'
import { SoftHitReport } from './SoftHitReport'
import { FinancingConfigDto } from './FinancingConfigDto'

export const expensesSchema = yup.default.object({
  financingProgramId: yup.default.string().nullable().default(''),
  monthlyInstallmentLoanPayment: yupExtInt.double.min(0),
  monthlyStudentLoanPayment: yupExtInt.double.min(0),
  monthlyLineOfCreditPayment: yupExtInt.double.min(0),
  monthlyCreditCardsPayment: yupExtInt.double.min(0),
  monthlyPaydayLoanPayment: yupExtInt.double.min(0),
  monthlyPersonalLoanPayment: yupExtInt.double.min(0),
  monthlyOtherLoanPayment: yupExtInt.double.min(0),
  monthlyOtherExpensePayment: yupExtInt.double.min(0),
  monthlyActiveIFLoanPayment: yupExtInt.double.min(0),
  otherExpensePaymentDescription: yup.default
    .string()
    .nullable()
    .max(50)
    .when('monthlyOtherExpensePayment', {
      is: (val: number) => {
        return val > 0
      },
      then: yup.default.string().required(),
    }),
  homeFeeTypeId: yup.default
    .mixed<EHomeStatus>()
    .oneOf([null, ...EHomeStatusList])
    .default(null)
    .nullable()
    .when('financingProgramId', {
      is: EFinancingProgram.Personal,
      then: (schema) => schema.required(),
    }),
  rentMonthly: yupExtInt.double,
  mortgageMonthly: yupExtInt.double,
  housingMonthly: yupExtInt.double,
  houseMarketValue: yupExtInt.double,
  landlord: yup.default.string().nullable().notRequired().default(''),
  monthlyOwnerInsuranceFee: yupExtInt.double.min(0),
  monthlyMortgageInsuranceFee: yupExtInt.double.min(0),
  monthlyMunicipalAndSchoolTaxPayment: yupExtInt.double.min(0),
  monthlyCondominiumFee: yupExtInt.double.min(0),
  monthlyPublicServiceFee: yupExtInt.double.min(0),
  monthlyRrspRefund: yupExtInt.double.min(0),
  monthlyOtherHomeFee: yupExtInt.double.min(0),
  isCompleted: yup.default.bool().default(false).nullable(),
})

export type ApplicantExpenses = yup.default.InferType<typeof expensesSchema>

export const ExpensesSchema = expensesSchema.when((value: ApplicantExpenses, schema: typeof expensesSchema) => {
  const additionalFields: Record<string, AnySchema> = {}
  if (value?.houseMarketValue || value?.housingMonthly) {
    additionalFields.homeFeeTypeId = yup.default.mixed<EHomeStatus>().oneOf(EHomeStatusList).required()
  }

  if (value?.homeFeeTypeId) {
    switch (value.homeFeeTypeId) {
      case EHomeStatus.Owner:
        additionalFields.monthlyHomePayment = yupExtInt.double.notRequired().nullable()
        break
      case EHomeStatus.OwnMobileHome:
      case EHomeStatus.OwnWithMortgage:
      case EHomeStatus.Renter:
        additionalFields.housingMonthly = yupExtInt.double.required().positive()
        additionalFields.houseMarketValue = yupExtInt.double.notRequired().nullable().min(0)
        break
      case EHomeStatus.WithParents:
      case EHomeStatus.ReserveHousing:
      case EHomeStatus.Other:
      default:
        additionalFields.housingMonthly = yupExtInt.double.required().min(0)
        additionalFields.houseMarketValue = yupExtInt.double.notRequired().nullable().min(0)
        break
    }
  }

  return schema.shape(additionalFields)
})

export const ApplicantAddressSchema = AddressSchema.shape({
  years: yupExtInt.integer.min(0),
  months: yupExtInt.integer.when('years', {
    is: (value: number | null) => {
      return !!value && value > 0
    },
    then: yupExtInt.integer.notRequired().min(0),
    otherwise: yupExtInt.integer.required().min(1),
  }),
})

export type ApplicantAddress = yup.default.InferType<typeof ApplicantAddressSchema>

export const JobSchema = yup.default.object({
  id: yup.default.string().default(null),
  employerName: yup.default.string().default('').required(),
  jobTitle: yup.default.string().default('').required(),
  jobType: yupExtInt.integer.required(),
  annualSalary: yupExtInt.integer.required().min(1),
  employerPhone: yup.default.string().IsValidCanadianPhone().nullable(),
  employerPhoneExt: yup.default.string().IsValidPhoneExtension().nullable(),
  address: AddressSchema.nullable().default(null),
  years: yupExtInt.integer.min(0),
  months: yupExtInt.integer.when('years', {
    is: (value: number | null) => {
      return !!value && value > 0
    },
    then: yupExtInt.integer.notRequired().min(0),
    otherwise: yupExtInt.integer.required().min(1),
  }),

  verificationContactName: yup.default.string().nullable(),
  verificationContactRole: yup.default.string().nullable(),
  verificationContactPhone: yup.default.string().nullable(),
})
export type ApplicantJob = yup.default.InferType<typeof JobSchema>

export const OtherIncomeSchema = yup.default.object({
  id: yup.default.string().default(null),
  typeId: yup.default.mixed<EOtherIncomeType>().required('numberRequired'),
  description: yup.default.string().nullable().when('typeId', {
    is: EOtherIncomeType.other,
    then: yup.default.string().required(),
  }),
  annualAmount: yupExtInt.integer.required(),
})
export type ApplicantOtherIncome = yup.default.InferType<typeof OtherIncomeSchema>

export const additionnalBankruptciesSchema = yup.default.object({
  id: yup.default.string().default(null),
  filedD: yup.default.date().required(),
  liabilityAmount: yupExtInt.integer.required(),
  intentCode: yup.default.string().required(),
  caseNumber: yup.default.string().required(),
  trusteeName: yup.default.string().nullable(),
})
export type AdditionnalBankruptcies = yup.default.InferType<typeof additionnalBankruptciesSchema>

const draftApplicantSchema = {
  id: yup.default.string().nullable().default(null), // le default null est nécessaire pour le createEntityAdapter
  middleName: yup.default
    .string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(50)
    .default('')
    .nullable(),
  relationWithApplicant: yup.default
    .mixed<ERelationToApplicant | undefined>()
    .nullable()
    .default('' as ERelationToApplicant)
    .when('isPrimaryApplicant', {
      is: false,
      then: (schema) => schema.required().oneOf(ERelationToApplicantList),
      otherwise: (schema) => schema.notRequired(),
    }),
  firstName: yup.default
    .string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(50)
    .required()
    .default(''),
  lastName: yup.default
    .string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(50)
    .required()
    .default(''),
  birthDate: yup.default.string().default('').isValidDate(),
  sin: yup.default.string().SinType().default(''),
  languageId: yupExtInt.integer.nullable(false),
  genderId: yupExtInt.integer.default(null),
  maritalStatusId: yupExtInt.integer.nullable(),
  homePhone: yup.default.string().default('').IsValidCanadianPhone().nullable(),
  cellPhone: yup.default.string().default('').IsValidCanadianPhone(),
  email: yup.default.string().notRequired().email().max(60).default(''),
  // Incomes
  currentJobs: yup.default.array(JobSchema).default([]),
  previousJobs: yup.default.array(JobSchema).default([]),
  otherIncomes: yup.default.array(OtherIncomeSchema).default([]),
  // address
  currentAddress: ApplicantAddressSchema,
  previousAddresses: yup.default.array().of(ApplicantAddressSchema),
  additionnalBankruptcies: yup.default.array(additionnalBankruptciesSchema).default([]),
  expenses: ExpensesSchema.notRequired().default(ExpensesSchema.getDefault()),
  isPrimaryApplicant: yup.default.bool().required().default(true),
}

export const DraftApplicantSchema = yup.default.object(draftApplicantSchema)

const fullApplicantSchema = { ...draftApplicantSchema }
fullApplicantSchema.birthDate = fullApplicantSchema.birthDate.required().isValidDate()
fullApplicantSchema.genderId = fullApplicantSchema.genderId.required()
fullApplicantSchema.languageId = fullApplicantSchema.languageId.required()
fullApplicantSchema.maritalStatusId = fullApplicantSchema.maritalStatusId.required()
fullApplicantSchema.cellPhone = fullApplicantSchema.cellPhone.IsValidCanadianPhone().required()
fullApplicantSchema.email = fullApplicantSchema.email.required()
fullApplicantSchema.currentAddress = FullAddressSchema

export const FullApplicantSchema = yup.default.object(fullApplicantSchema)

const releaseRequiredByLoanId = {
  loanId: yup.default.string().default(''),
  isRequired: yup.default.boolean(),
}

export const ReleaseRequiredByLoanId = yup.default.object(releaseRequiredByLoanId)

export const buildDraftCreditApplicationSchemaObject = (financingConfig?: FinancingConfigDto) => {
  const draftCreditApplicationSchema = {
    id: yup.default.string().default(''), // le default null est nécessaire pour le createEntityAdapter
    financingProgramId: yup.default.mixed<EFinancingProgram>().required().oneOf(EFinancingProgramList),

    loanPurposeId: yup.default
      .mixed<ELoanPurpose>()
      .nullable()
      .default('' as ELoanPurpose)
      .when('financingProgramId', {
        is: EFinancingProgram.Personal,
        then: (schema) =>
          schema
            .required('Loan purpose is required for Personal financing')
            .oneOf(ELoanPurposeList, 'Invalid loan purpose'),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
    requestedLoanAmount: yupExtInt.double
      .nullable()
      .default(null)
      .when('financingProgramId', (financingProgramId: EFinancingProgram, schema: NumberSchema<number | null>) => {
        const isRequired: boolean = get(
          Constants.financingProgramConfigs,
          financingProgramId?.toLowerCase(),
          defaultProgramConfig,
        ).requestedLoanAmountIsRequired

        if (isRequired && financingConfig) {
          return yupExtInt.double
            .required()
            .min(financingConfig.minimumLoanAmount)
            .max(financingConfig.maximumLoanAmount)
        }
        return schema.notRequired().nullable()
      }),

    merchantId: yup.default.string().required().default(''),
    status: yup.default
      .mixed<ECreditApplicationStatus>()
      .oneOf(ECreditApplicationStatusList)
      .required()
      .default(ECreditApplicationStatus.Draft),
    applicant: DraftApplicantSchema.required().default(DraftApplicantSchema.getDefault()),
    coApplicant: DraftApplicantSchema.nullable().default(null),
    editLocked: yup.default.bool().default(false),
    correspondanceLanguageId: yupExtInt.integer,
    versionTag: yup.default.string(),
    beneficiaryTypeId: yupExtInt
      .numberEnum<EBeneficiaryType>(EBeneficiaryTypeList)
      .required()
      .default(EBeneficiaryType.Applicant),
    otherBeneficiaryFirstName: yup.default
      .string()
      .trim()
      .when('loanPurposeId', (loanPurposeId, schema: StringSchema) => {
        if (loanPurposeId === ELoanPurpose.Veterinary || loanPurposeId === ELoanPurpose.GoodsAndServices) {
          return schema.notRequired().nullable()
        }
        return schema.when('beneficiaryTypeId', {
          is: EBeneficiaryType.Other,
          then: yup.default.string().required(),
          otherwise: yup.default.string().notRequired().nullable(),
        })
      }),

    otherBeneficiaryLastName: yup.default
      .string()
      .trim()
      .when('loanPurposeId', (loanPurposeId, schema: StringSchema) => {
        if (loanPurposeId === ELoanPurpose.GoodsAndServices) {
          return schema.notRequired().nullable()
        }
        return schema.when('beneficiaryTypeId', {
          is: EBeneficiaryType.Other,
          then: yup.default.string().required(),
          otherwise: yup.default.string().notRequired().nullable(),
        })
      }),
    originSystemId: yup.default
      .mixed<EOriginSystemId>()
      .oneOf(EOriginSystemIdList)
      .required()
      .default(EOriginSystemId.Backoffice),
    brokerReferenceNumber: yupExtInt.integer.nullable().when('financingProgramId', {
      is: EFinancingProgram.AutoRsla,
      then: yupExtInt.integer.required(),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
  }

  return draftCreditApplicationSchema
}

export const buildDraftCreditApplicationSchema = (financingConfig?: FinancingConfigDto) => {
  return yup.default
    .object(buildDraftCreditApplicationSchemaObject(financingConfig))
    .test('test-DistinctCellphone', 'common.errors.distinctCellphone', function (value) {
      const { createError } = this
      if (value?.coApplicant !== null) {
        const coappCellPhone = value.coApplicant.cellPhone
        const appCellphone = value.applicant.cellPhone
        if (appCellphone && coappCellPhone && value) {
          return (
            appCellphone !== coappCellPhone ||
            createError({ path: 'coApplicant.cellPhone', message: 'common.errors.distinctCellphone' })
          )
        }
      }
      return true
    })
}
export const DraftCreditApplicationSchema = buildDraftCreditApplicationSchema()

export const buildFullCreditApplicationSchema = (financingConfig?: FinancingConfigDto) => {
  return buildDraftCreditApplicationSchema(financingConfig).shape({
    merchantId: buildDraftCreditApplicationSchemaObject(financingConfig).merchantId.required(),
    applicant: FullApplicantSchema.required(),
    coApplicant: FullApplicantSchema.nullable().default(null),
  })
}
export const FullCreditApplicationSchema = buildFullCreditApplicationSchema()

export type Applicant = yup.default.InferType<typeof DraftApplicantSchema> & {
  creditDecision: ApplicantCreditDecision
  softHitReport: SoftHitReport
  hardHitReport: HardHitReport
  prequalificationDecision: PrequalificationDecision
  existingCustomerReference: ExistingCustomerReference | null
}

export type ProofsOfRelease = {
  creditReportUniqueNumber: string
  releaseRequiredByLoanId: Record<string, boolean>
  decidorFullName: string
  decidorId: string
  updatedOn: Date
  financingProgramId: EFinancingProgram
  creditApplicationId: string
  versionTag: string
}

export const BankAccountSchema = yup.default.object({
  bankName: yup.default.string().required().max(50),
  institutionNumber: yup.default
    .string()
    .required()
    .min(3)
    .max(3)
    .matches(/^[0-9]+$/),
  transitNumber: yup.default
    .string()
    .required()
    .min(5)
    .max(5)
    .matches(/^[0-9]+$/),
  accountNumber: yup.default
    .string()
    .required()
    .min(7)
    .max(12)
    .matches(/^[0-9]+$/),
})

export type BankAccount = yup.default.InferType<typeof BankAccountSchema>

type EditCreditApplicationDto = yup.default.InferType<typeof FullCreditApplicationSchema>
export type CreditApplication = EditCreditApplicationDto & {
  applicant: Applicant
  coApplicant: Applicant | null
  currentRevisionId: string
  referenceNumber: number
  parentReferenceNumber: number | null
  parentCreditApplicationId: string | null
  normsCreditDecision: NormCreditDecision | null
  finalCreditDecision: CreditDecision
  decisionOverrides: CreditDecisionOverrides | null
  refinancing: Refinancing | null
  createdOn: Date
  updatedOn: Date
  expiresOn: Date
  createdByUserFullname: string
  correspondanceLanguageId: number
  cvtNumber: number | null
  loanId: number | null
  loanPurposeId: string
  proofsOfRelease: ProofsOfRelease
  paymentBankAccount: BankAccount
  prequalificationDecision: PrequalificationDecision
  merchantPaymentPlanId: EPaymentPlan
  beneficiaryTypeId: number
  otherBeneficiaryFirstName: string
  otherBeneficiaryLastName: string
  consentSoftHit: boolean
  consentHardHit: boolean
  initialRequestedLoanAmount: number | null
  brokerReferenceNumber: number | null
  isRush: boolean
}
