import React, { useEffect } from 'react'
import { Grid, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { reportErrorToConsole } from '@src/services/error-logger'
import { CreditApplication, EApplicantType } from '../../../data/types'
import ApplicantSummary from './applicantSummary'
import IncomesTable from './incomesTable'
import CreditReportComponent from './creditReport'
import { useAppSelector } from '../../../data/store'
import { CreditReport } from '../../../data/types/CreditReportSchema'
import { creditSelectors } from '../../../data/store/CreditApplication'
import allApis from '../../../data/api'
import { appSelectors } from '../../../data/store/AppStore'

type Props = {
  applicantCreditReport: CreditReport | null
  setApplicantCreditReport: (creditReport: CreditReport) => void
  coApplicantCreditReport: CreditReport | null
  setCoApplicantCreditReport: (creditReport: CreditReport) => void
  creditApplication: CreditApplication
  selectedTabIndex: number
  refreshReport: (applicant: 'applicant' | 'coApplicant') => void
  userCanRefreshCreditReport: boolean
  editDisabled: boolean
}

const ApplicantInformations = ({
  creditApplication,
  applicantCreditReport,
  setApplicantCreditReport,
  coApplicantCreditReport,
  setCoApplicantCreditReport,
  refreshReport,
  selectedTabIndex,
  userCanRefreshCreditReport,
  editDisabled,
}: Props) => {
  const navigate = useNavigate()
  const apiClient = useAppSelector(appSelectors.getApiClient)
  const selectedTab: EApplicantType = selectedTabIndex === 2 ? EApplicantType.CoApplicant : EApplicantType.Applicant
  const selectedApplicant =
    selectedTab === EApplicantType.Applicant ? creditApplication?.applicant : creditApplication?.coApplicant
  const externalReportedIncomesForApplicant = useAppSelector(creditSelectors.getFlinksIncomesForApplicant)
  const externalReportedIncomesForCoApplicant = useAppSelector(creditSelectors.getFlinksIncomesForCoApplicant)
  const shouldFetchApplicantCreditReport = selectedTab === EApplicantType.Applicant && !applicantCreditReport
  const shouldFetchCoApplicantCreditReport = selectedTab === EApplicantType.CoApplicant && !coApplicantCreditReport
  const decision =
    selectedTab === EApplicantType.Applicant
      ? creditApplication.applicant.prequalificationDecision?.decision
      : creditApplication.coApplicant?.prequalificationDecision?.decision
  const handleSelectedTab = (index: number) => {
    const { location } = window
    const updateSearch = new URLSearchParams(location.search)
    updateSearch.set('tabIndex', encodeURIComponent(index))
    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: true })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          creditApplication &&
          apiClient &&
          selectedApplicant?.hardHitReport?.creditReportId &&
          (shouldFetchApplicantCreditReport || shouldFetchCoApplicantCreditReport)
        ) {
          const currentSelectedApplicantCreditReport = await allApis.credit.getCreditReportByCreditReportId(apiClient, {
            financingProgramId: creditApplication.financingProgramId,
            creditApplicationId: creditApplication.id,
            creditReportId: selectedApplicant.hardHitReport.creditReportId,
          })
          if (selectedTab === EApplicantType.Applicant) {
            setApplicantCreditReport(currentSelectedApplicantCreditReport.creditReport)
          }
          if (selectedTab === EApplicantType.CoApplicant) {
            setCoApplicantCreditReport(currentSelectedApplicantCreditReport.creditReport)
          }
        }
      } catch (fetchingError) {
        console.error('Error fetching credit reports:', fetchingError)
      }
    }

    fetchData().catch(reportErrorToConsole)
  }, [
    creditApplication,
    selectedApplicant,
    apiClient,
    selectedTab,
    setApplicantCreditReport,
    setCoApplicantCreditReport,
    shouldFetchApplicantCreditReport,
    shouldFetchCoApplicantCreditReport,
  ])

  const theme = useTheme()

  const incomes = (name?: EApplicantType) => {
    return name === EApplicantType.Applicant
      ? creditApplication.applicant.currentJobs
      : creditApplication.coApplicant!.currentJobs
  }

  const otherIncomes = (name?: EApplicantType) => {
    return name === EApplicantType.Applicant
      ? creditApplication.applicant.otherIncomes
      : creditApplication.coApplicant!.otherIncomes
  }

  const externalReportedIncome = (name?: EApplicantType) => {
    return name === EApplicantType.Applicant
      ? externalReportedIncomesForApplicant
      : externalReportedIncomesForCoApplicant
  }
  const shoulDisplayIncome = useAppSelector(creditSelectors.getFinancingProgramConfigs).useIncomeSection

  return (
    <>
      <Grid container marginBottom={2}>
        <Grid
          item
          xs={12}
          md={6}
          border="1px solid"
          borderColor={theme.palette.divider}
          borderRadius={2}
          sx={{
            backgroundColor: selectedTab === EApplicantType.Applicant ? theme.palette.action.selected : '',
          }}
        >
          <ApplicantSummary
            applicant={creditApplication.applicant}
            creditApplicationId={creditApplication.id}
            financingProgramId={creditApplication.financingProgramId}
            onClickExpand={() => handleSelectedTab(1)}
            editDisabled={editDisabled}
          />
        </Grid>
        {creditApplication.coApplicant && (
          <Grid
            item
            xs={12}
            md={6}
            border="1px solid"
            borderColor={theme.palette.divider}
            borderRadius={2}
            sx={{
              backgroundColor: selectedTab === EApplicantType.CoApplicant ? theme.palette.action.selected : '',
            }}
          >
            <ApplicantSummary
              applicant={creditApplication.coApplicant}
              isCoapplicant
              creditApplicationId={creditApplication.id}
              financingProgramId={creditApplication.financingProgramId}
              onClickExpand={() => handleSelectedTab(2)}
              editDisabled={editDisabled}
            />
          </Grid>
        )}
      </Grid>
      {shoulDisplayIncome && (
        <IncomesTable
          incomes={incomes(selectedTab)}
          otherIncomes={otherIncomes(selectedTab)}
          externalReportedIncome={externalReportedIncome(selectedTab)?.annualAverageGrossIncome}
        />
      )}

      {decision && (
        <CreditReportComponent
          refreshReport={() => refreshReport(selectedTab)}
          creditReport={selectedTab === EApplicantType.Applicant ? applicantCreditReport : coApplicantCreditReport}
          softHitReport={
            selectedTab === EApplicantType.Applicant
              ? creditApplication.applicant.softHitReport
              : creditApplication.coApplicant?.softHitReport
          }
          decision={decision}
          userCanRefreshCreditReport={userCanRefreshCreditReport}
          financingProgramId={creditApplication.financingProgramId}
          editDisabled={editDisabled}
          existingOnGoingLoans={
            selectedTab === EApplicantType.Applicant
              ? creditApplication.applicant.existingCustomerReference?.existingOnGoingLoans
              : creditApplication.coApplicant?.existingCustomerReference?.existingOnGoingLoans
          }
        />
      )}
    </>
  )
}

export default ApplicantInformations
