import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosInstance, AxiosRequestConfig, CreateAxiosDefaults } from 'axios'
import { keyBy } from 'lodash-es'
import { FinancingConfigDto } from '@src/data/types/FinancingConfigDto'
import { ProvinceConfigs } from '@src/data/types/ProvinceConfigs'
import { ProductsProvider } from '@src/data/types/ProductsProvider'
import type { TRootState } from '..'
import type { ValueListDto } from '../../api/config/config-api'
import { BusinessError, EFinancingProgram, Territory } from '../../types'
import { Conflict } from '../../types/Conflict'
import { DocumentType } from '../../types/DocumentType'
import { TaskType } from '../../types/TaskType'
import { TranslatedEnum } from '../../types/TranslatedEnum'
import buildValueList from '../common/selectorUtils'

interface AppState {
  globalTechnicalError: string | null
  businessErrors: BusinessError[]
  axiosBaseConfig: AxiosRequestConfig
  creditRefusalReasons: ValueListDto | null
  merchantTags: ValueListDto | null
  territories: Territory[]
  financingConfigs: FinancingConfigDto[]
  ProvinceConfigurations: ProvinceConfigs[]
  productsProviders: ProductsProvider[]
  iFinanceHolidays: Date[]
  language: string
  isInitializing: boolean
  jobTypeEnum: Record<string, TranslatedEnum>
  otherIncomeTypesEnum: Record<string, TranslatedEnum>
  applicantTypeEnum: Record<string, TranslatedEnum>
  financingProgramEnum: Record<string, TranslatedEnum>
  autoPlanEnums: Record<string, TranslatedEnum>
  personalLoanPlanEnums: Record<string, TranslatedEnum>
  ifxPlanEnums: Record<string, TranslatedEnum>
  b2cPlanEnums: Record<string, TranslatedEnum>
  vlvrPlanEnums: Record<string, TranslatedEnum>
  documentTypes: Record<number, DocumentType>
  taskTypes: Record<number, TaskType>
  conflict: Conflict | null
  isDeprecated: boolean
}

const initialState: AppState = {
  globalTechnicalError: null,
  businessErrors: [],
  creditRefusalReasons: null,
  merchantTags: null,
  territories: [],
  productsProviders: [],
  iFinanceHolidays: [],
  financingConfigs: [],
  ProvinceConfigurations: [],
  jobTypeEnum: {} as Record<string, TranslatedEnum>,
  otherIncomeTypesEnum: {} as Record<string, TranslatedEnum>,
  applicantTypeEnum: {} as Record<string, TranslatedEnum>,
  financingProgramEnum: {} as Record<string, TranslatedEnum>,
  autoPlanEnums: {} as Record<string, TranslatedEnum>,
  personalLoanPlanEnums: {} as Record<string, TranslatedEnum>,
  ifxPlanEnums: {} as Record<string, TranslatedEnum>,
  b2cPlanEnums: {} as Record<string, TranslatedEnum>,
  vlvrPlanEnums: {} as Record<string, TranslatedEnum>,
  documentTypes: {} as Record<number, DocumentType>,
  taskTypes: {} as Record<number, TaskType>,
  language: 'fr',
  axiosBaseConfig: {
    baseURL: import.meta.env.VITE_BACKOFFICE_API_URL,
    withCredentials: true,
    headers: {
      'X-requestor': `backofficeapp:${import.meta.env.VITE_VERSION}`,
    },
  },
  isInitializing: true,
  conflict: null,
  isDeprecated: false,
}

let globalApiClient: AxiosInstance | null = null
let globalApiConfig: CreateAxiosDefaults | null = null

export const appSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setTechnicalError: (state, action: PayloadAction<string>) => {
      state.globalTechnicalError = action.payload
    },
    setBusinessErrors: (state, action: PayloadAction<BusinessError[]>) => {
      state.businessErrors = action.payload
    },
    setCreditRefusalReasons: (state, action: PayloadAction<ValueListDto>) => {
      state.creditRefusalReasons = action.payload
    },
    setMerchantTags: (state, action: PayloadAction<ValueListDto>) => {
      state.merchantTags = action.payload
    },
    setTerritories: (state, action: PayloadAction<Territory[]>) => {
      state.territories = action.payload
    },
    setProductProviders: (state, action: PayloadAction<ProductsProvider[]>) => {
      state.productsProviders = action.payload
    },
    setHolidays: (state, action: PayloadAction<Date[]>) => {
      state.iFinanceHolidays = action.payload
    },
    setFinancingConfigs: (state, action: PayloadAction<FinancingConfigDto[]>) => {
      state.financingConfigs = action.payload
    },
    setProvinceConfigurations: (state, action: PayloadAction<ProvinceConfigs[]>) => {
      state.ProvinceConfigurations = action.payload
    },
    resetErrors: (state) => {
      state.businessErrors = []
      state.globalTechnicalError = null
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload
    },
    setJwtToken: (state, action: PayloadAction<string>) => {
      const { headers } = state.axiosBaseConfig
      state.axiosBaseConfig.headers = { ...headers, Authorization: `Bearer ${action.payload}` }
    },
    setInitializing: (state, action: PayloadAction<boolean>) => {
      state.isInitializing = action.payload
    },
    setEnums: (state, action: PayloadAction<Record<string, Record<string, TranslatedEnum>>>) => {
      state.jobTypeEnum = action.payload.JobTypeEnum
      state.otherIncomeTypesEnum = action.payload.OtherIncomeTypesEnum
      state.applicantTypeEnum = action.payload.ApplicantType
      state.financingProgramEnum = action.payload.FinancingProgramEnum
      state.autoPlanEnums = action.payload.AutoPlanEnums
      state.personalLoanPlanEnums = action.payload.PersonalLoanPlanEnums
      state.ifxPlanEnums = action.payload.IfxPlanEnums
      state.vlvrPlanEnums = action.payload.VlvrPlanEnums
      state.b2cPlanEnums = action.payload.B2cPlanEnums
    },
    setDocumentTypes: (state, action: PayloadAction<Record<number, DocumentType>>) => {
      state.documentTypes = action.payload
    },
    setTaskTypes: (state, action: PayloadAction<Record<number, TaskType>>) => {
      state.taskTypes = action.payload
    },
    setConflict: (state, action: PayloadAction<Conflict | null>) => {
      state.conflict = action.payload
    },
    setDeprecated: (state, action: PayloadAction<boolean>) => {
      state.isDeprecated = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const appActions = appSlice.actions

export default appSlice.reducer

// Other code such as selectors can use the imported `RootState` type

const baseAppSelectors = {
  getTechnicalError: (state: TRootState) => state.app.globalTechnicalError,
  getBusinessError: (state: TRootState) => state.app.businessErrors,
  getAxiosConfig: (state: TRootState) => state.app.axiosBaseConfig,
  getCurrentLang: (state: TRootState) => state.app.language,
  getJobTypeEnum: (state: TRootState) => state.app.jobTypeEnum,
  getHolidays: (state: TRootState) => state.app.iFinanceHolidays,
  getOtherIncomeTypesEnum: (state: TRootState) => state.app.otherIncomeTypesEnum,
  getProvinceConfigurations: (state: TRootState) => state.app.ProvinceConfigurations,
  getApplicantTypeEnum: (state: TRootState) => state.app.applicantTypeEnum,
  getFinancingProgramEnum: (state: TRootState) => state.app.financingProgramEnum,

  getActiveAndInactiveFinancingPrograms: createSelector(
    (financingProgram?: EFinancingProgram | null) => financingProgram,

    (financingProgram) => {
      switch (financingProgram) {
        case EFinancingProgram.Auto:
          return {
            activePrograms: [],
            inactivePrograms: [EFinancingProgram.Auto],
          }

        case EFinancingProgram.Personal:
          return {
            activePrograms: [EFinancingProgram.Personal],
            inactivePrograms: [],
          }

        case EFinancingProgram.B2c:
          return {
            activePrograms: [],
            inactivePrograms: [EFinancingProgram.B2c],
          }

        case EFinancingProgram.IfXpress:
          return {
            activePrograms: [EFinancingProgram.IfXpress],
            inactivePrograms: [],
          }

        case EFinancingProgram.Products:
          return {
            activePrograms: [],
            inactivePrograms: [EFinancingProgram.Products],
          }

        case EFinancingProgram.AutoRsla:
          return {
            activePrograms: [EFinancingProgram.AutoRsla],
            inactivePrograms: [],
          }

        default:
          return {
            activePrograms: [],
            inactivePrograms: [],
          }
      }
    },
  ),
  getDocumentTypes: createSelector(
    [
      (state: TRootState) => state.app.documentTypes,
      (_state: TRootState, financingProgramId: EFinancingProgram | null = null) => financingProgramId,
    ],
    (documentTypes, financingProgramId) => {
      if (financingProgramId === null) return documentTypes
      return keyBy(
        Object.values(documentTypes).filter(
          (x) =>
            x.restrictedToFinancingProgramIds.includes(financingProgramId) ||
            x.restrictedToFinancingProgramIds.length === 0,
        ),
        'id',
      )
    },
  ),
  getTaskTypes: createSelector(
    [
      (state: TRootState) => state.app.taskTypes,
      (_state: TRootState, financingProgramId: EFinancingProgram | null = null) => financingProgramId,
    ],
    (taskTypes, financingProgramId) => {
      if (financingProgramId === null) return taskTypes
      return keyBy(
        Object.values(taskTypes).filter(
          (x) =>
            x.restrictedToFinancingProgramIds.includes(financingProgramId) ||
            x.restrictedToFinancingProgramIds.length === 0,
        ),
        'id',
      )
    },
  ),
  getCreditRefusalReasonsList: (state: TRootState) => state.app.creditRefusalReasons,
  getApiClient: createSelector(
    (state: TRootState) => state.app.axiosBaseConfig,
    (config: CreateAxiosDefaults) => {
      if (globalApiConfig !== config) {
        globalApiConfig = config
        globalApiClient = axios.create(config)
      }
      return globalApiClient
    },
  ),
  getDocumentTypesAddableManually: createSelector(
    [
      (state: TRootState) => state.app.documentTypes,
      (_state: TRootState, financingProgramId: EFinancingProgram) => financingProgramId,
    ],
    (documentTypes, financingProgramId) => {
      return keyBy(
        Object.values(documentTypes).filter(
          (x) =>
            x.canBeAddedManually === true &&
            (x.restrictedToFinancingProgramIds.includes(financingProgramId) ||
              x.restrictedToFinancingProgramIds.length === 0),
        ),
        'id',
      )
    },
  ),
  getTaskTypesAddableManually: createSelector(
    [
      (state: TRootState) => state.app.taskTypes,
      (_state: TRootState, financingProgramId: EFinancingProgram) => financingProgramId,
    ],
    (taskTypes, financingProgramId) => {
      return keyBy(
        Object.values(taskTypes).filter(
          (x) =>
            x.canBeAddedManually === true &&
            (x.restrictedToFinancingProgramIds.includes(financingProgramId) ||
              x.restrictedToFinancingProgramIds.length === 0),
        ),
        'id',
      )
    },
  ),
  getAllTaskTypes: createSelector(
    [
      (state: TRootState) => state.app.taskTypes,
      (_state: TRootState, financingProgramId: EFinancingProgram) => financingProgramId,
    ],
    (taskTypes, financingProgramId) => {
      return keyBy(
        Object.values(taskTypes).filter(
          (x) =>
            x.restrictedToFinancingProgramIds.includes(financingProgramId) ||
            x.restrictedToFinancingProgramIds.length === 0,
        ),
        'id',
      )
    },
  ),
  getTerritoryList: (state: TRootState) => state.app.territories,
  getProductProviders: (state: TRootState) => state.app.productsProviders,
  isLoading: (state: TRootState) => state.app.isInitializing,
  conflict: (state: TRootState) => state.app.conflict,
  isDeprecated: (state: TRootState) => state.app.isDeprecated,
}

const getMerchantTagsSelector = createSelector(
  (state: TRootState) => state.app.merchantTags,
  baseAppSelectors.getCurrentLang,
  buildValueList,
)

export const appSelectors = {
  ...baseAppSelectors,
  getCreditRefusalReasons: createSelector(
    (state: TRootState) => state.app.creditRefusalReasons,
    baseAppSelectors.getCurrentLang,
    buildValueList,
  ),
  getMerchantTags: getMerchantTagsSelector,
  getMerchantTagKeys: createSelector(getMerchantTagsSelector, (tags) => tags.map((tag) => tag.id)),
  getPlanEnumForProgram: createSelector(
    [
      (state: TRootState) => state.app,
      (_state: TRootState, financingProgram?: EFinancingProgram | null) => financingProgram,
    ],
    (appState, financingProgram) => {
      switch (financingProgram) {
        case EFinancingProgram.Auto: {
          return appState.autoPlanEnums
        }
        case EFinancingProgram.IfXpress: {
          return appState.ifxPlanEnums
        }
        case EFinancingProgram.Personal: {
          return appState.personalLoanPlanEnums
        }
        case EFinancingProgram.B2c: {
          return appState.b2cPlanEnums
        }
        case EFinancingProgram.AutoRsla:
        case EFinancingProgram.Products:
          return {}

        default: {
          return {
            ...appState.autoPlanEnums,
            ...appState.ifxPlanEnums,
            ...appState.personalLoanPlanEnums,
            ...appState.b2cPlanEnums,
          }
        }
      }
    },
  ),
  getAllPlansForPrograms: createSelector(
    [(state: TRootState) => state.app, (_state: TRootState, programs: EFinancingProgram[]) => programs],
    (appState, programs) => {
      return programs.flatMap((program) => {
        switch (program) {
          case EFinancingProgram.Auto:
            return Object.values(appState.autoPlanEnums || {})
          case EFinancingProgram.IfXpress:
            return Object.values(appState.ifxPlanEnums || {})
          case EFinancingProgram.Personal:
            return Object.values(appState.personalLoanPlanEnums || {})
          case EFinancingProgram.B2c:
            return Object.values(appState.b2cPlanEnums || {})
          case EFinancingProgram.AutoRsla:
          case EFinancingProgram.Products:
            return []
          default:
            return []
        }
      })
    },
  ),
  getFinancingConfig: createSelector(
    [
      (state: TRootState) => state.app.financingConfigs,
      (_state: TRootState, financingProgram?: EFinancingProgram | null) => financingProgram,
    ],
    (configs, financingProgram) =>
      configs.find((x) => x.financingProgramId === financingProgram) ??
      ({
        effectiveRateThreshold: 0,
        largeLoanAmount: 0,
        longTerm: 0,
        maximumLoanAmount: 0,
        minimumLoanAmount: 0,
      } as FinancingConfigDto),
  ),
}
