import React from 'react'
import {
  Control,
  FieldError,
  FieldErrors,
  UseFormRegister,
  UseFormWatch,
  UseFormSetValue,
  Merge,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormTrigger,
} from 'react-hook-form'
import { Box, Divider, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ApplicantInformationParameters from './components/applicantInformationComponent'
import AddressComponent from './components/AddressComponent'
import ApplicantExpense from './components/applicantExpenseComponent'
import ApplicantIncomeSection from './components/applicantIncomeComponent'
import {
  Applicant,
  CreditApplication,
  EApplicantType,
  EFinancingProgram,
  EProvince,
  FinancingProgramConfig,
} from '../../../../../../data/types'

type Props = {
  register: UseFormRegister<CreditApplication>
  watch: UseFormWatch<CreditApplication>
  setValue: UseFormSetValue<CreditApplication>
  getValues: UseFormGetValues<CreditApplication>
  trigger: UseFormTrigger<CreditApplication>
  errors: Merge<FieldError, FieldErrorsImpl<Applicant>> | undefined
  applicantType: EApplicantType
  formControl: Control<CreditApplication>
  editDisabled: boolean
  financingProgramId: EFinancingProgram
  financingProgramConfig: FinancingProgramConfig
}

const ApplicantParameters = ({
  register,
  watch,
  setValue,
  getValues,
  trigger,
  errors,
  applicantType: name,
  formControl,
  editDisabled,
  financingProgramId,
  financingProgramConfig,
}: Props) => {
  const { t } = useTranslation()
  const applicantProvince = watch(`${name}.currentAddress.stateIso`)
  const useExpenseIncomeSections =
    financingProgramId !== EFinancingProgram.Products ||
    (financingProgramId === EFinancingProgram.Products && applicantProvince === EProvince.quebec)
  const typedErrors = errors as FieldErrors<Applicant>
  return (
    <>
      <ApplicantInformationParameters
        register={register}
        getValues={getValues}
        trigger={trigger}
        errors={typedErrors}
        name={name}
        formControl={formControl}
        editDisabled={editDisabled}
      />
      <Box
        sx={{
          height: 20,
        }}
      />
      <Divider>
        <Typography variant="h4" component="span" gutterBottom>
          {t('editCreditApplication.address.label')}
        </Typography>
      </Divider>
      <AddressComponent
        formControl={formControl}
        register={register}
        errors={typedErrors?.currentAddress}
        editDisabled={editDisabled}
        name={`${name}.currentAddress`}
      />
      {financingProgramConfig.useExpenseSection && useExpenseIncomeSections && (
        <>
          <Divider>
            <Typography variant="h4" component="span" gutterBottom>
              {t('editCreditApplication.expenses.label')}
            </Typography>
          </Divider>
          <ApplicantExpense
            register={register}
            watch={watch}
            setValue={setValue}
            errors={typedErrors?.expenses}
            name={`${name}.expenses`}
            showDetailedForm={false}
            financingProgramId={financingProgramId}
            editDisabled={editDisabled}
          />
        </>
      )}
      {financingProgramConfig.useIncomeSection && useExpenseIncomeSections && (
        <ApplicantIncomeSection
          register={register}
          errors={typedErrors?.otherIncomes}
          formControl={formControl}
          name={name}
          editDisabled={editDisabled}
        />
      )}
    </>
  )
}

export default ApplicantParameters
