import { EProductsInsurance } from '@src/data/types'
import { FundingComputedDto } from '@src/data/types/FundingComputedDto'
import { FundingInfoDto } from '@src/data/types/FundingInfoDto'
import { ProductsComputeResultDto } from '@src/data/types/ProductsComputeResultDto'
import { ProductsProvider } from '@src/data/types/ProductsProvider'
import { ProductsInsurancePlan, ProductsWorksheet } from '@src/data/types/ProductsWorksheetSchema'

export const canComputeFunding = (computeDto: FundingInfoDto, minLoanAmount: number): boolean => {
  return (
    computeDto.amountRequested >= minLoanAmount &&
    !!computeDto.paymentFrequency &&
    !!computeDto.paymentPlanId &&
    !!computeDto.deliveryOn &&
    !!computeDto.firstPaymentOn &&
    !!computeDto.term &&
    !!computeDto.interestRate &&
    !!computeDto.stateIso
  )
}

export const getEmptyComputedFundingDto = (): FundingComputedDto => {
  return {
    lenderFee: 0,
    lenderFeeRate: 0,
    totalInterestAmount: 0,
    insuranceFee: 0,
    insuranceTax: 0,
    paymentForFrequency: 0,
    effectiveRate: 0,
    requestId: '',
  }
}

export const getEmptyProductsComputeResponse = (): ProductsComputeResultDto => {
  return {
    paymentAmountForFrequency: 0,
    interestRate: 0,
    effectiveRate: 0,
    annualFees: 0,
  } as ProductsComputeResultDto
}

export const getProvidersInsurancePlans = (
  productsWorksheet: ProductsWorksheet,
  productsProviders: ProductsProvider[],
): ProductsInsurancePlan[] => {
  const insurances: ProductsInsurancePlan[] = []
  if (productsWorksheet?.creditInsurance)
    insurances.push({
      ...productsWorksheet.creditInsurance,
      providerName: productsProviders.find((p) => p.id === productsWorksheet.creditInsurance.provider)?.name ?? '',
      type: EProductsInsurance.CreditInsurance,
    })
  if (productsWorksheet?.replacementOrGapInsurance)
    insurances.push({
      ...productsWorksheet.replacementOrGapInsurance,
      providerName:
        productsProviders.find((p) => p.id === productsWorksheet.replacementOrGapInsurance.provider)?.name ?? '',
      type: EProductsInsurance.ReplacementOrGapInsurance,
    })
  if (productsWorksheet?.extendedWarranty)
    insurances.push({
      ...productsWorksheet.extendedWarranty,
      providerName: productsProviders.find((p) => p.id === productsWorksheet.extendedWarranty.provider)?.name ?? '',
      type: EProductsInsurance.ExtendedWarranty,
    })
  return insurances
}
