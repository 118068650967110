import React from 'react'
import { Grid } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { EMask } from '../../../../../../../data/types/constants'
import AddressComponent from './AddressComponent'
import { Address, ApplicantJob, JobSchema } from '../../../../../../../data/types'
import { ActionsDialog, SelectComponent, InputTextField, InputTextFieldWithMask } from '../../../../../../../components'
import { useAppSelector } from '../../../../../../../data/store'
import { appSelectors } from '../../../../../../../data/store/AppStore'
import { TranslatedEnum } from '../../../../../../../data/types/TranslatedEnum'

type Props = {
  open: boolean
  title: string
  defaultValue: ApplicantJob
  onConfirm: (data: ApplicantJob) => void
  onCancel: () => void
  editDisabled: boolean
}

const EditJobDialog = ({ defaultValue, onConfirm, onCancel, open, title, editDisabled }: Props) => {
  const { t } = useTranslation()
  const jobTypeEnum = useAppSelector(appSelectors.getJobTypeEnum)
  const lang = useAppSelector(appSelectors.getCurrentLang) as keyof TranslatedEnum

  const {
    register,
    trigger,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm<ApplicantJob>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: defaultValue,
    resolver: yupResolver(JobSchema),
  })

  React.useEffect(() => {
    reset(defaultValue)
  }, [defaultValue, reset])

  const isAddressEmpty = (address: Address | null) => {
    return address && Object.values(address).every((a) => a === null || a === undefined)
  }

  const handleSubmit = async () => {
    await trigger(['annualSalary', 'jobType', 'jobTitle', 'employerName', 'employerPhone', 'years', 'months']).then(
      (isValid) => {
        if (isValid) {
          const jobCopy = { ...getValues() }
          if (isAddressEmpty(jobCopy.address)) jobCopy.address = null
          onConfirm(jobCopy)
        }
      },
    )
  }

  return (
    <ActionsDialog title={title} open={open} onCancel={onCancel} onConfirm={handleSubmit}>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.annualSalary}
            label={t('editCreditApplication.income.job.annualSalary')}
            {...register('annualSalary')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectComponent
            items={Object.values(jobTypeEnum).map((item) => ({ label: item[lang], value: item.id }))}
            label={t('editCreditApplication.income.job.jobType') as string}
            {...register('jobType')}
            error={errors?.jobType}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            inputProps={{ maxLength: 50 }}
            error={errors?.jobTitle}
            label={t('editCreditApplication.income.job.jobTitle')}
            {...register('jobTitle')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.employerName}
            inputProps={{ maxLength: 100 }}
            label={t('editCreditApplication.income.job.employerName')}
            {...register('employerName')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Controller
            name="employerPhone"
            control={control}
            render={({ field }) => (
              <InputTextFieldWithMask
                mask={EMask.phoneNumberMask}
                error={errors?.employerPhone}
                label={t('editCreditApplication.income.job.employerPhone')}
                {...field}
                onChange={(e) => field.onChange(e.target.value.substring(1).replace(/-/g, ''))}
              />
            )}
          />
        </Grid>
        <Grid item xs={3} md={1}>
          <InputTextField
            error={errors?.employerPhoneExt}
            label={t('editCreditApplication.income.job.employerPhoneExt')}
            {...register('employerPhoneExt')}
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.years}
            label={t('editCreditApplication.address.since.years')}
            {...register('years')}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <InputTextField
            error={errors?.months}
            label={t('editCreditApplication.address.since.months')}
            {...register('months')}
          />
        </Grid>
        <AddressComponent
          formControl={control}
          register={register}
          errors={errors?.address}
          isCommercial
          name="address"
          includeDuration={false}
          editDisabled={editDisabled}
        />
      </Grid>
    </ActionsDialog>
  )
}

export default React.memo(EditJobDialog)
