import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { B2cWorksheet } from '@src/data/types/B2cWorksheetSchema'
import { AxiosInstance } from 'axios'
import { AutoRslaWorksheet } from '@src/data/types/AutoRslaWorksheet'
import { ProductsWorksheet } from '@src/data/types/ProductsWorksheetSchema'
import type { TRootState } from '..'
import allApi from '../../api'
import { AutoWorksheet, EFinancingProgram, FullCreditApplication, PersonalLoanWorksheet } from '../../types'
import { ElectronicSignature } from '../../types/ElectronicSignature'
import { apiStateActions } from '../ApiStateStore'
import { autoWorksheetActions } from '../AutoWorksheet'
import { b2cWorksheetActions } from '../B2cWorksheet'
import { creditActions } from '../CreditApplication'
import { GET_CREDIT_BY_ID } from '../CreditApplication/credit-application-store'
import { documentActions } from '../Document'
import { electronicSignatureActions } from '../ElectronicSignature'
import { personalLoanWorksheetActions } from '../PersonalLoanWorksheet'
import { requiredExternalStepActions } from '../RequiredExternalStep'
import { autoRslaWorksheetActions } from '../AutoRslaWorksheet'
import { productsWorksheetActions } from '../ProductsWorksheet'

const getMostRecentElectronicSignature = (electronicSignatures: ElectronicSignature[]) => {
  return (
    electronicSignatures.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())[0] ?? null
  )
}

export function setFullCreditApp(
  fullCreditApp: FullCreditApplication,
  dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
) {
  dispatch(creditActions.setCurrent(fullCreditApp.credit))

  if (fullCreditApp.credit.financingProgramId === EFinancingProgram.Auto)
    dispatch(autoWorksheetActions.setCurrent(fullCreditApp.worksheet as AutoWorksheet))

  if (fullCreditApp.credit.financingProgramId === EFinancingProgram.Personal) {
    dispatch(personalLoanWorksheetActions.setCurrent(fullCreditApp.worksheet as PersonalLoanWorksheet))
  }

  if (fullCreditApp.credit.financingProgramId === EFinancingProgram.B2c) {
    dispatch(b2cWorksheetActions.setCurrent(fullCreditApp.worksheet as B2cWorksheet))
  }

  if (fullCreditApp.credit.financingProgramId === EFinancingProgram.AutoRsla) {
    dispatch(autoRslaWorksheetActions.setCurrent(fullCreditApp.worksheet as AutoRslaWorksheet))
  }

  if (fullCreditApp.credit.financingProgramId === EFinancingProgram.Products) {
    dispatch(productsWorksheetActions.setCurrent(fullCreditApp.worksheet as ProductsWorksheet))
  }

  dispatch(creditActions.setCurrentCreditApplicationTasks(fullCreditApp.tasks))
  dispatch(creditActions.setProgressionStatus(fullCreditApp.progressionStatus))
  dispatch(creditActions.setFlinksIncomes(fullCreditApp.flinksIncomes))
  dispatch(documentActions.setDocumentList(fullCreditApp.documents))
  dispatch(documentActions.setRequiredDocumentList(fullCreditApp.requiredDocuments))
  dispatch(requiredExternalStepActions.setRequiredExternalSteps(fullCreditApp.requiredExternalSteps))
  dispatch(
    electronicSignatureActions.setElectronicSignature(
      getMostRecentElectronicSignature(fullCreditApp.electronicSignatures),
    ),
  )
}

export const fullCreditApplicationEffects = {
  getById: (id: string, financingProgramId: EFinancingProgram) => {
    return async (
      apiClient: AxiosInstance,
      dispatch: ThunkDispatch<TRootState, undefined, AnyAction>,
      /* select: TypedUseSelectorHook<TRootState>, */
    ): Promise<FullCreditApplication> => {
      try {
        // log api is loading
        dispatch(apiStateActions.logApiCallInitiated(GET_CREDIT_BY_ID))
        // perform actual request
        const ret = await allApi.fullCredit.getById(apiClient, { id, financingProgramId })

        if (ret) {
          setFullCreditApp(ret, dispatch)

          const merchant = await allApi.config.getMerchantById(apiClient, { id: ret.credit.merchantId })
          dispatch(creditActions.setMerchant(merchant))
        }

        return ret
      } finally {
        dispatch(apiStateActions.logApiCallCompleted(GET_CREDIT_BY_ID))
      }
    }
  },
}
