import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { findIndex } from 'lodash-es'
import {
  EApplicantType,
  Constants,
  ECreditApplicationStatus,
  ECreditApplicationStep,
  EDecision,
  EDocumentStatus,
  EDocumentType,
  EProgressionStatus,
  EProvince,
  ETasksStatus,
  ETaskType,
  EWorksheetDecision,
  defaultProgramConfig,
  EFinancingProgram,
  EAfterNormEffectsEnum,
} from '../../types/constants'
import type { TRootState } from '..'
import {
  Applicant,
  BaseWorksheet,
  CreditApplication,
  CreditApplicationTask,
  CreditTaskComment,
  EContinueFinancingDisableReason,
  Merchant,
  NormsMessage,
  PreviousApplication,
} from '../../types'
import { apiStateSelectors } from '../ApiStateStore'

import { CreditApplicationReservation } from '../../types/CreditApplicationReservation'
import { CreditDecisionHistory, IncomesHistory } from '../../types/IncomesHistory'
import { autoworksheetSelectors } from '../AutoWorksheet'
import { Refinancing } from '../../types/RefinancingSchema'
import { personalLoanWorksheetSelectors } from '../PersonalLoanWorksheet/personal-loan-worksheet-store'
import { b2cWorksheetSelectors } from '../B2cWorksheet/b2c-worksheet-store'
import { FlinksIncome } from '../../types/FlinksIncome'
import { CreditDecision } from '../../types/CreditDecisionSchema'
import { CreditDashboardEntry } from '../../types/CreditDashboardEntry'
import { productsWorkSheetSelectors } from '../ProductsWorksheet'
import { appSelectors } from '../AppStore'

export const creditSlice = createSlice({
  name: 'credit',
  initialState: {
    current: null as CreditApplication | null,
    dashboardEntry: null as CreditDashboardEntry | null,
    merchant: null as Merchant | null,
    list: [] as CreditApplication[],
    taskList: [] as CreditApplicationTask[],
    applicantPreviousApplications: [] as PreviousApplication[],
    coApplicantPreviousApplications: [] as PreviousApplication[],
    incomesHistory: null as IncomesHistory | null,
    lock: null as CreditApplicationReservation | null,
    progressionStatus: null as EProgressionStatus | null,
    decisionHistory: [] as CreditDecisionHistory[],
    flinksIncomes: [] as FlinksIncome[],
    isRush: false,
  },
  reducers: {
    setIncomesHistory: (state, action: PayloadAction<IncomesHistory | null>) => {
      state.incomesHistory = action.payload
    },
    setDecisionHistory: (state, action: PayloadAction<CreditDecisionHistory[]>) => {
      state.decisionHistory = action.payload
    },
    setCurrent: (state, action: PayloadAction<CreditApplication | null>) => {
      state.current = action.payload
    },
    setList: (state, action: PayloadAction<CreditApplication[]>) => {
      state.list = action.payload
    },
    setCurrentCreditApplicationTasks: (state, action: PayloadAction<CreditApplicationTask[]>) => {
      state.taskList = action.payload
    },
    setFlinksIncomes: (state, action: PayloadAction<FlinksIncome[]>) => {
      state.flinksIncomes = action.payload
    },
    setCreditTask: (state, action: PayloadAction<CreditApplicationTask>) => {
      const index = findIndex(state.taskList, { id: action.payload.id })
      if (index === -1) {
        action.payload.comments = []
        state.taskList.push(action.payload)
      } else {
        action.payload.comments = state.taskList[index].comments
        state.taskList.splice(index, 1, action.payload)
      }
    },
    setCreditTaskComment: (state, action: PayloadAction<CreditTaskComment>) => {
      state.taskList.find((task) => task.id === action.payload.taskId)?.comments.unshift(action.payload)
    },
    removeCreditTask: (state, action: PayloadAction<CreditApplicationTask>) => {
      const index = findIndex(state.taskList, { id: action.payload.id })
      state.taskList.splice(index, 1)
    },
    setApplicantPreviousApplications: (state, action: PayloadAction<PreviousApplication[]>) => {
      state.applicantPreviousApplications = action.payload
    },
    setCoapplicantPreviousApplications: (state, action: PayloadAction<PreviousApplication[]>) => {
      state.coApplicantPreviousApplications = action.payload
    },
    setCreditApplicationLock: (state, action: PayloadAction<CreditApplicationReservation | null>) => {
      state.lock = action.payload
    },
    setRefinancing: (state, action: PayloadAction<Refinancing>) => {
      if (state.current) {
        state.current.refinancing = action.payload
      }
    },
    setDashboardEntry: (state, action: PayloadAction<CreditDashboardEntry>) => {
      state.dashboardEntry = action.payload
    },
    setMerchant: (state, action: PayloadAction<Merchant | null>) => {
      state.merchant = action.payload
    },
    setProgressionStatus: (state, action: PayloadAction<EProgressionStatus>) => {
      state.progressionStatus = action.payload
    },
  },
})

export default creditSlice.reducer

export const creditActions = creditSlice.actions

export const GET_CREDIT_BY_ID = 'Applications/getById'
export const GET_CREDITS_BY_FILTERS = 'Applications/getByFilters'
export const SAVE_CREDIT = 'Applications/save'
export const GET_CURRENT_CREDIT_TASKS = 'Applications/getCurrentCreditTasks'
export const GET_CREDIT_REPORTS = 'Applications/getCreditReports'
export const SAVE_CREDIT_TASK = 'Applications/saveCreditTask'
export const SAVE_CREDIT_TASK_COMMENT = 'Applications/saveCreditTaskComment'
export const SAVE_FINAL_CREDIT_DECISION = 'Applications/SaveFinalDecision'
export const REMOVE_CREDIT_TASK = 'Applications/RemoveCreditTask'
export const SAVE_BANISHMENT = 'banishment/save'
export const CHANGE_STATUS = 'Applications/changeStatus'
export const CHANGE_RUSH = 'Applications/changeRush'
export const GET_PREVIOUS_APPLICANT_APPLICATIONS_BY_FILTER = 'previousApplicantApplications/getByFilter'
export const GET_PREVIOUS_COAPPLICANT_APPLICATIONS_BY_FILTER = 'previousCoApplicantApplications/getByFilter'
export const DOWNLOAD_CREDIT_PDF = 'Applications/downloadCreditApplicationPDF'
export const GET_CREDIT_APPLICATION_LOCK = 'Applications/getCreditApplicationLock'
export const CREATE_CREDIT_APPLICATION_LOCK = 'Applications/createCreditApplicationLock'
export const DELETE_CREDIT_APPLICATION_LOCK = 'Applications/deleteCreditApplicationLock'
export const UPDATE_CREDIT_APPLICATION_LOCK = 'Applications/updateCreditApplicationLock'
export const PAY_OUT = 'Applications/payOut'
export const GET_MESSAGE = 'Applications/getMessageList'
export const SAVE_MESSAGE = 'Applications/saveMessage'
export const CONFIRM_ALL_INCOMES = 'Applications/ConfirmAllIncomes'
export const GET_INCOMES_HISTORY = 'hystory/incomes'
export const CREATE_CVT_NUMBER = 'Applications/createCvtNumber'
export const DOWNLOAD_CVT_PDF = 'Applications/credit/downloadCvtPDF'
export const SET_REFINANCING = 'Applications/setRefinancing'
export const SET_EXISTING_APPLICANT_CUSTOMER_REFERENCE = 'Applications/setApplicantCustomerReference'
export const SET_EXISTING_COAPPLICANT_CUSTOMER_REFERENCE = 'Applications/setCoapplicantCustomerReference'
export const SET_PROOF_OF_RELEASE = 'Applications/setProofOfRelease'
export const UPDATE_INCOMES = 'Applications/updateIncomes'
export const CREATE_LOAN = 'Applications/createLoan'
export const SET_PAYMENT_ACCOUNT = 'Applications/setPaymentAccount'
export const SEND_FUND_DEPOSIT_CONFIRMATION = 'Applications/FundDepositConfirmation'
export const REFINANCE_CREDIT_APPLICATION = 'Applications/RefinanceCreditApplication'
export const COPY_CREDIT_APPLICATION_AS_DRAFT = 'Applications/CopyCreditApplicationAsDraft'
export const RESET_VOUCHED = 'Applications/ResetVouched'

const creditSelectorsPartial = {
  hasMerchantRateModifier: (state: TRootState) => {
    return (
      state.credit.current?.normsCreditDecision &&
      state.credit.current.normsCreditDecision.afterNormEffects.some(
        (x) => x === EAfterNormEffectsEnum.MerchantRateModifier,
      )
    )
  },
  isLoadingCreditApplication: (state: TRootState) => apiStateSelectors.isLoading(state, GET_CREDIT_BY_ID),
  isSavingCreditApplication: (state: TRootState) => apiStateSelectors.isLoading(state, SAVE_CREDIT),
  isLoadingPreviousApplicantApplication: (state: TRootState) =>
    apiStateSelectors.isLoading(state, GET_PREVIOUS_APPLICANT_APPLICATIONS_BY_FILTER),
  isLoadingPreviousCoApplicantApplication: (state: TRootState) =>
    apiStateSelectors.isLoading(state, GET_PREVIOUS_COAPPLICANT_APPLICATIONS_BY_FILTER),
  isLoadingList: (state: TRootState) => apiStateSelectors.isLoading(state, GET_CREDITS_BY_FILTERS),
  isLoadingTasks: (state: TRootState) => apiStateSelectors.isLoading(state, GET_CURRENT_CREDIT_TASKS),
  isLoadingIncomesRevision: (state: TRootState) => apiStateSelectors.isLoading(state, GET_INCOMES_HISTORY),
  isLoadingLock: (state: TRootState) => apiStateSelectors.isLoading(state, GET_CREDIT_APPLICATION_LOCK),
  getCurrent: (state: TRootState) => state.credit.current,
  hasCoapplicant: (state: TRootState) => state.credit.current?.coApplicant !== null,
  getList: (state: TRootState) => state.credit.list,
  isSavingTask: (state: TRootState) => apiStateSelectors.isLoading(state, SAVE_CREDIT_TASK),
  getCurrentCreditApplicationTasks: (state: TRootState) => state.credit.taskList,
  isSavingTaskComment: (state: TRootState) => apiStateSelectors.isLoading(state, SAVE_CREDIT_TASK_COMMENT),
  isSavingFinalDecision: (state: TRootState) => apiStateSelectors.isLoading(state, SAVE_FINAL_CREDIT_DECISION),
  isSendingFundDepositConfirmation: (state: TRootState) =>
    apiStateSelectors.isLoading(state, SEND_FUND_DEPOSIT_CONFIRMATION),
  isTogglingRush: (state: TRootState) => apiStateSelectors.isLoading(state, CHANGE_RUSH),
  getFlinksIncomesForApplicant: (state: TRootState) => {
    const flinksForApplicant = state.credit.flinksIncomes.filter((x) => x.applicantType === EApplicantType.Applicant)
    return flinksForApplicant.length > 0 ? flinksForApplicant[0] : null
  },
  getFlinksIncomesForCoApplicant: (state: TRootState) => {
    const flinksForCoApplicant = state.credit.flinksIncomes.filter(
      (x) => x.applicantType === EApplicantType.CoApplicant,
    )
    return flinksForCoApplicant.length > 0 ? flinksForCoApplicant[0] : null
  },
  getTaskListToDisplay: (state: TRootState) => {
    return state.credit.taskList.filter((x) => x.typeId !== ETaskType.ConfirmAllIncomes)
  },
  getConfirmAllIncomesStatus: (state: TRootState) => {
    return state.credit.taskList.find((x) => x.typeId === ETaskType.ConfirmAllIncomes)?.status
  },
  getCanConfirmAllIncomes: (state: TRootState) => {
    return (
      state.credit.current?.normsCreditDecision !== null &&
      state.credit.current?.normsCreditDecision?.decision !== EDecision.declined &&
      !state.credit.taskList.some(
        (x) =>
          ((x.typeId === ETaskType.ConfirmJobIncome ||
            x.typeId === ETaskType.ConfirmOtherIncome ||
            x.typeId === ETaskType.VerifyBankruptcyHistory) &&
            x.status === ETasksStatus.ToDo) ||
          (x.typeId === ETaskType.ConfirmAllIncomes && x.status === ETasksStatus.Completed),
      ) &&
      !state.document.documentRequestedList.some(
        (x) =>
          !state.document.documentList.some(
            (y) =>
              y.status === EDocumentStatus.Approved && x.typeId === y.typeId && x.applicantType === y.applicantType,
          ) &&
          (x.requiredBeforeStep === ECreditApplicationStep.Credit ||
            x.requiredBeforeStep === ECreditApplicationStep.Financing),
      )
    )
  },
  getAllTask: (state: TRootState) => state.credit.taskList,

  canChangeLockOfCreditApplication: (state: TRootState) => {
    return (
      (state.credit.current?.finalCreditDecision.decision === EDecision.accept &&
        (state.worksheet.current?.finalDecision?.decision === EWorksheetDecision.Approved ||
          state.personalLoanWorksheet.current?.finalDecision?.decision === EWorksheetDecision.Approved ||
          state.b2cWorksheet.current?.finalDecision?.decision === EWorksheetDecision.Approved ||
          state.productsWorksheet.current?.finalDecision.decision === EWorksheetDecision.Approved) &&
        state.credit.taskList.some(
          (x) => x.typeId === ETaskType.ConfirmAllIncomes && x.status === ETasksStatus.Completed,
        ) &&
        state.document.documentList.some(
          (x) => x.typeId === EDocumentType.SignedCVT && x.status === EDocumentStatus.Approved,
        ) &&
        (state.credit.current.applicant.currentAddress.stateIso === EProvince.quebec
          ? state.credit.current.applicant.expenses.isCompleted
          : true)) === true && state.credit.current?.status !== ECreditApplicationStatus.Completed
    )
  },

  getPreviousApplicantApplication: (state: TRootState) => state.credit.applicantPreviousApplications,
  getPreviousCoApplicantApplication: (state: TRootState) => state.credit.coApplicantPreviousApplications,

  getIncomesHistory: (state: TRootState) => state.credit.incomesHistory,
  getDecisionHistory: (state: TRootState) => {
    const isRevised = (previousDecision: CreditDecision | null, currentDecision: CreditDecision | null) =>
      previousDecision?.decision !== currentDecision?.decision ||
      previousDecision?.interestRate !== currentDecision?.interestRate ||
      previousDecision?.maxAmountFinanced !== currentDecision?.maxAmountFinanced ||
      previousDecision?.maxPmtAmount !== currentDecision?.maxPmtAmount ||
      previousDecision?.maxTermDuration !== currentDecision?.maxTermDuration

    const historys = [] as CreditDecisionHistory[]
    let previousDecision: CreditDecision | null = null

    state.credit.decisionHistory.forEach((history) => {
      if (isRevised(previousDecision, history.finalCreditDecision)) historys.push(history)
      previousDecision = history.finalCreditDecision
    })

    return historys
  },

  getCreditApplicationLock: (state: TRootState) => state.credit.lock,
  getFinancingProgramConfigs: (state: TRootState) => {
    const programId = state.credit.current?.financingProgramId
    if (programId !== undefined) {
      return Constants.financingProgramConfigs[programId]
    }
    return defaultProgramConfig
  },

  getApplicantLaw134IsCompleted: (state: TRootState) => state.credit.current?.applicant.expenses?.isCompleted,

  getCoApplicantLaw134IsCompleted: (state: TRootState) =>
    state.credit.current?.coApplicant?.expenses.isCompleted ?? true,

  getApplicantIsFromQc: (state: TRootState) =>
    state.credit.current?.applicant?.currentAddress?.stateIso === EProvince.quebec,
  getCoApplicantIsFromQc: (state: TRootState) =>
    state.credit.current?.coApplicant?.currentAddress?.stateIso === EProvince.quebec,
  getProgressionStatus: (state: TRootState) => state.credit.progressionStatus,
  getMerchant: (state: TRootState) => state.credit.merchant,
  isFinalDecisionPending: (state: TRootState) => {
    return (
      state.credit.current?.normsCreditDecision === null &&
      state.credit.current?.prequalificationDecision === null &&
      state.credit.current?.finalCreditDecision?.decision === EDecision.pending &&
      state.credit.current?.status === ECreditApplicationStatus.Active &&
      state.credit.current.consentSoftHit &&
      state.credit.current.consentHardHit
    )
  },
  isPrequalificationPending: (state: TRootState) => {
    return (
      state.credit.current?.prequalificationDecision === null &&
      state.credit.current?.status === ECreditApplicationStatus.Active &&
      state.credit.current.consentSoftHit &&
      !state.credit.current.consentHardHit
    )
  },
  isOnlyPrequalificationDone: (state: TRootState) => {
    return (
      state.credit.current?.normsCreditDecision === null &&
      state.credit.current?.prequalificationDecision !== null &&
      state.credit.current?.consentSoftHit
    )
  },
  createdWithPromotion: (state: TRootState) => state.credit.current?.merchantPaymentPlanId !== null,
}

function getTranslationFromListOfKeys(
  keys: string[] | undefined,
  applicantType: EApplicantType | null,
): NormsMessage[] {
  const normsToReturn = [] as NormsMessage[]

  if (keys) {
    keys.forEach((x) => {
      normsToReturn.push({ id: x, message: x, applicantType })
    })
  }

  return normsToReturn
}

function getNormMessagesForApplicant(applicant: Applicant | null | undefined, applicantType: EApplicantType) {
  let normsList = [] as NormsMessage[]
  if (applicant?.creditDecision) {
    normsList = normsList.concat(getTranslationFromListOfKeys(applicant.creditDecision?.infoCodes, applicantType))
    normsList = normsList.concat(getTranslationFromListOfKeys(applicant.creditDecision?.warningCodes, applicantType))
    normsList = normsList.concat(
      getTranslationFromListOfKeys(applicant.creditDecision?.refusalReasonCodes, applicantType),
    )
  }

  return normsList
}

function getNormMessagesForApplication(application: CreditApplication | null | undefined) {
  let normsList = [] as NormsMessage[]
  if (application?.finalCreditDecision?.warningCodes)
    normsList = normsList.concat(getTranslationFromListOfKeys(application.finalCreditDecision.warningCodes, null))
  if (application?.finalCreditDecision?.refusalReasonCodes)
    normsList = normsList.concat(getTranslationFromListOfKeys(application.finalCreditDecision.refusalReasonCodes, null))
  return normsList
}

function allTasksCompletedForStep(step: ECreditApplicationStep, tasks: CreditApplicationTask[]) {
  return !tasks.some((x) => x.status === ETasksStatus.ToDo && x.requiredBeforeStep === step)
}
const getCurrentCreditApplicationNormsApplicant = createSelector(
  [creditSelectorsPartial.getCurrent],
  (currentCreditApplication) => {
    return getNormMessagesForApplicant(currentCreditApplication?.applicant, EApplicantType.Applicant)
  },
)
const getCurrentCreditApplicationNormsCoapplicant = createSelector(
  [creditSelectorsPartial.getCurrent],
  (currentCreditApplication) => {
    return getNormMessagesForApplicant(currentCreditApplication?.coApplicant, EApplicantType.CoApplicant)
  },
)
const getCurrentCreditApplicationSpecificNorms = createSelector(
  [creditSelectorsPartial.getCurrent],
  (currentCreditApplication) => {
    return getNormMessagesForApplication(currentCreditApplication)
  },
)

export const creditSelectors = {
  ...creditSelectorsPartial,
  getCurrentCreditApplicationNormsApplicant,
  getCurrentCreditApplicationNormsCoapplicant,
  getCurrentCreditApplicationSpecificNorms,
  areAllTasksRequiredForCreditCompleted: createSelector([creditSelectorsPartial.getAllTask], (tasks) => {
    return allTasksCompletedForStep(ECreditApplicationStep.Credit, tasks)
  }),
  areAllTasksRequiredForCVTCompleted: createSelector([creditSelectorsPartial.getAllTask], (tasks) => {
    return (
      allTasksCompletedForStep(
        ECreditApplicationStep.CVT,
        tasks.filter((x) => x.typeId !== ETaskType.ApproveWorksheet), // Without approve cvt task
      ) &&
      allTasksCompletedForStep(ECreditApplicationStep.Financing, tasks) &&
      allTasksCompletedForStep(ECreditApplicationStep.Credit, tasks)
    )
  }),
  areAllTasksRequiredForPayoutCompleted: createSelector([creditSelectorsPartial.getAllTask], (tasks) => {
    return (
      allTasksCompletedForStep(ECreditApplicationStep.PayOut, tasks) &&
      allTasksCompletedForStep(ECreditApplicationStep.CVT, tasks) &&
      allTasksCompletedForStep(ECreditApplicationStep.Credit, tasks) &&
      allTasksCompletedForStep(ECreditApplicationStep.Financing, tasks)
    )
  }),
  getCurrentCreditApplicationNorms: createSelector(
    [
      getCurrentCreditApplicationNormsApplicant,
      getCurrentCreditApplicationNormsCoapplicant,
      getCurrentCreditApplicationSpecificNorms,
    ],
    (applicantNormsMessages, coApplicantNormsMessage, currentCreditNorms) => {
      let normsList = [] as NormsMessage[]
      normsList = applicantNormsMessages.concat(coApplicantNormsMessage).concat(currentCreditNorms)

      return normsList
    },
  ),
  getPreviousApplicantApplication: createSelector(
    [creditSelectorsPartial.getPreviousApplicantApplication, creditSelectorsPartial.getCurrent],
    (PreviousApplicantApplication, currentCreditApplication) => {
      return PreviousApplicantApplication.filter((x) => x.creditApplicationId !== currentCreditApplication?.id)
    },
  ),
  getPreviousCoApplicantApplication: createSelector(
    [creditSelectorsPartial.getPreviousCoApplicantApplication, creditSelectorsPartial.getCurrent],
    (PreviousCoApplicantApplication, currentCreditApplication) => {
      return PreviousCoApplicantApplication.filter((x) => x.creditApplicationId !== currentCreditApplication?.id)
    },
  ),
  getMerchantPaymentPlan: createSelector(
    [creditSelectorsPartial.getCurrent, personalLoanWorksheetSelectors.getCurrent, creditSelectorsPartial.getMerchant],
    (creditApplication, personalLoanWorksheet, merchant) => {
      const paymentPlanId = personalLoanWorksheet?.paymentPlanId ?? creditApplication?.merchantPaymentPlanId
      if (paymentPlanId && merchant && merchant.paymentPlans?.length > 0) {
        return merchant.paymentPlans.find((p) => p.id === paymentPlanId)
      }
      return null
    },
  ),
  isAbleToPrintCvt: createSelector(
    [
      creditSelectorsPartial.getCurrent,
      autoworksheetSelectors.getCurrent,
      personalLoanWorksheetSelectors.getCurrent,
      b2cWorksheetSelectors.getCurrent,
      productsWorkSheetSelectors.getCurrent,
      creditSelectorsPartial.getApplicantLaw134IsCompleted,
      creditSelectorsPartial.getCoApplicantLaw134IsCompleted,
      creditSelectorsPartial.getApplicantIsFromQc,
      creditSelectorsPartial.getCoApplicantIsFromQc,
    ],
    (
      creditApplication,
      autoWorksheet,
      personalLoanWorksheet,
      b2cWorksheet,
      productsWorksheet,
      applicantLaw134IsCompleted,
      coApplicantLaw134IsCompleted,
      applicantIsFromQc,
      coApplicantIsFromQc,
    ) => {
      const worksheet: BaseWorksheet | null =
        autoWorksheet ?? personalLoanWorksheet ?? b2cWorksheet ?? productsWorksheet
      return (
        (creditApplication?.finalCreditDecision?.decision === EDecision.accept &&
          worksheet?.finalDecision?.decision === EWorksheetDecision.Approved &&
          (!applicantIsFromQc || applicantLaw134IsCompleted) &&
          (!coApplicantIsFromQc || coApplicantLaw134IsCompleted)) === true
      )
    },
  ),
  canContinuePersonnalLoanFinancing: createSelector(
    [
      creditSelectorsPartial.getCurrent,
      personalLoanWorksheetSelectors.getCurrent,
      (state: TRootState) => appSelectors.getFinancingConfig(state, EFinancingProgram.Personal),
    ],
    (creditApplication, personalLoanWorksheet, financingConfig) => {
      const today = new Date()
      const date90daysbeforsToday = new Date(new Date().setDate(today.getDate() - 90))
      const createdOn = new Date(creditApplication?.createdOn ?? '')
      const reasons: EContinueFinancingDisableReason[] = []

      if (
        (creditApplication?.finalCreditDecision?.maximumAvalaibleAmount ?? 0) -
          (personalLoanWorksheet?.amountRequested ?? 0) <
        financingConfig.minimumLoanAmount
      )
        reasons.push(EContinueFinancingDisableReason.MaximumAvalaibleAmountIsTooLow)

      if (createdOn <= date90daysbeforsToday) reasons.push(EContinueFinancingDisableReason.CreatedOnIsExpired)

      if (personalLoanWorksheet?.finalDecision.decision !== EWorksheetDecision.Approved)
        reasons.push(EContinueFinancingDisableReason.WorksheetIsNotApproved)

      if (creditApplication?.applicant.existingCustomerReference?.customerId === '')
        reasons.push(EContinueFinancingDisableReason.CustomerNeedToBeCreated)

      return reasons
    },
  ),
}
