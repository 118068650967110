import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { FieldError } from 'react-hook-form'
import translateErrorCode from './TranslateError'

interface Props extends Omit<TextFieldProps, 'error'> {
  error?: FieldError
  maxLength?: number
  forceBaseColor?: boolean
}

const InputTextField = React.forwardRef<Props, Props>(({ error, forceBaseColor, ...othersProps }, ref) => {
  return (
    <TextField
      {...othersProps}
      inputProps={othersProps.inputProps}
      inputRef={ref}
      error={error !== undefined}
      helperText={translateErrorCode(error)}
      variant="outlined"
      fullWidth
      InputLabelProps={{ shrink: true }}
      {...(forceBaseColor && {
        sx: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: `${othersProps.color}.main`,
            },
            '&:hover fieldset': {
              borderColor: `${othersProps.color}.dark`,
            },
            '&.Mui-focused fieldset': {
              borderColor: `${othersProps.color}.main`,
            },
          },
          '& .MuiInputLabel-root': {
            color: `${othersProps.color}.main`,
          },
        },
      })}
    />
  )
})

export default InputTextField
