import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Alert from '@mui/material/Alert'
import { Button, Link, Stack, Typography } from '@mui/material'
import { Refresh as RefreshIcon } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { ExistingOnGoingLoans } from '@src/data/types/CustomerReferenceSchema'
import {
  BankruptcyHistory,
  ForeignInquiries,
  FraudWarnings,
  Funding,
  Identification,
  LocalInquiries,
  Score,
  SpecialServices,
} from './creditReportComponents'
import { CreditReport } from '../../../data/types/CreditReportSchema'
import { SoftHitReport } from '../../../data/types/SoftHitReport'
import { EDecision, EFinancingProgram } from '../../../data/types'
import CollectionItems from './creditReportComponents/collectionItemsSection'
import LegalItems from './creditReportComponents/legalItemsSection'
import ConsumerStatement from './creditReportComponents/consumerStatementsSection'
import OnGoingLoans from './creditReportComponents/iFinanceOnGoingLoansSection'

type OnePageLinksPros = {
  data: Array<{ key: number; id: string; label: string }>
}
const OnePageLinks = ({ data }: OnePageLinksPros) => {
  const handleClick = (id: string) => (event: React.MouseEvent) => {
    event.preventDefault()
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
  return (
    <Typography variant="body1" gutterBottom component="div" sx={{ mt: 2 }}>
      {data.map((item, index) => (
        <Link key={item.key} href={`#${item.id}`} onClick={handleClick(item.id)} style={{ textDecoration: 'unset' }}>
          {item.label} {index !== data.length - 1 && ' - '}
        </Link>
      ))}
    </Typography>
  )
}

type Props = {
  creditReport: CreditReport | null
  softHitReport: SoftHitReport | undefined
  refreshReport: () => void
  userCanRefreshCreditReport: boolean
  decision: EDecision
  financingProgramId: EFinancingProgram
  editDisabled: boolean
  existingOnGoingLoans: ExistingOnGoingLoans[] | undefined
}

const CreditReportComponent = ({
  creditReport,
  softHitReport,
  refreshReport,
  userCanRefreshCreditReport,
  decision,
  financingProgramId,
  editDisabled,
  existingOnGoingLoans,
}: Props) => {
  const { t } = useTranslation()

  const LINKS = [
    { key: 0, id: 'identification', label: 'Identification' },
    { key: 1, id: 'score', label: 'Score' },
    { key: 2, id: 'consumerStatements', label: t('creditReport.consumerStatements') },
    { key: 3, id: 'fraudAlert', label: t('creditReport.fraudAlert') },
    { key: 4, id: 'bankruptcy', label: t('creditReport.bankruptcyHistory') },
    { key: 5, id: 'funding', label: t('creditReport.funding') },
    { key: 6, id: 'funding', label: t('creditReport.paymentReports') },
    { key: 7, id: 'onGoingLoans', label: t('creditReport.onGoingLoans') },
    { key: 8, id: 'localInquiries', label: t('creditReport.localInquiries') },
    { key: 9, id: 'foreignInquiries', label: t('creditReport.foreignInquiries') },
    { key: 10, id: 'specialServices', label: t('creditReport.specialServices') },
    { key: 11, id: 'collectionItems', label: t('creditReport.collectionItems') },
    { key: 12, id: 'legalItems', label: t('creditReport.legalItems') },
  ]

  return (
    <>
      {creditReport && <OnePageLinks data={LINKS} />}

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
                  <Typography variant="h4" component="span" textAlign="center">
                    {t('credit.credit')}
                  </Typography>
                  <Button
                    variant="contained"
                    disabled={editDisabled || (!creditReport && !softHitReport) || !userCanRefreshCreditReport}
                    startIcon={<RefreshIcon />}
                    color="secondary"
                    onClick={refreshReport}
                  >
                    {t('credit.refreshEquifaxData')}
                  </Button>
                  <Typography variant="body1" component="div">
                    ERS : {softHitReport?.ers}
                  </Typography>
                </Stack>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!creditReport && !softHitReport && (
              <TableRow>
                <TableCell>
                  <Alert severity="info">{t('credit.unavailable')}</Alert>
                </TableCell>
              </TableRow>
            )}
            {softHitReport && creditReport === null && decision === EDecision.declined && (
              <TableRow>
                <TableCell>
                  <Alert severity="info">{t('credit.notQualified')}</Alert>
                </TableCell>
              </TableRow>
            )}
            {creditReport && (
              <>
                <Identification creditReport={creditReport} />
                <Score creditReport={creditReport} financingProgramId={financingProgramId} />
                <ConsumerStatement consumerStatements={creditReport.consumerStatements} />
                <FraudWarnings fraudWarnings={creditReport.fraudWarnings} />
                <CollectionItems collectionItems={creditReport.collections} />
                <LegalItems legalItems={creditReport.legalItems} />
                <BankruptcyHistory bankruptcys={creditReport.bankruptcies} />
                <Funding trades={creditReport.trades} extTrades={creditReport.extTrades} />
                {existingOnGoingLoans && <OnGoingLoans loans={existingOnGoingLoans} />}
                <LocalInquiries localInquiries={creditReport.localInquiries} />
                <ForeignInquiries foreignInquiries={creditReport.foreignInquiries} />
                <SpecialServices specialServices={creditReport.specialServices} />
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default CreditReportComponent
