import { InferType, NumberSchema, object } from 'yup'
import { min } from 'lodash-es'
import { baseWorksheetSchema } from './BaseWorksheetSchema'
import { MerchantPayment } from './MerchantPayment'
import yupExtInt from './common/SchemaTypes'
import * as yup from './common/yup-extended'
import {
  IFinanceBaseWorksheet,
  IFinanceBaseWorksheetDecisionDTOSchema,
  IFinanceBaseWorksheetRevision,
} from './IFinanceBaseWorksheetSchema'
import { EProductsInsurance } from './constants'

const MIN_TERM = 12

export const baseInsuranceSchema = object({
  provider: yup.default.string().nullable().default(null),
  police: yup.default.string().nullable().default(null),
  term: yupExtInt.integer.min(MIN_TERM),
  amount: yupExtInt.double,
  coverageStartDate: yup.default
    .date()
    .nullable()
    .transform((value: Date | null, originalValue: string | null): Date | null => {
      if (!originalValue) return null
      return value
    })
    .default(null),
})

export type ProductsInsurance = InferType<typeof baseInsuranceSchema>

export type ProductsInsurancePlan = ProductsInsurance & {
  providerName: string
  type: EProductsInsurance
}

const resolveInsuranceMaxTerm = (schema: typeof baseInsuranceSchema, vehicleFinancingTerm: number) => {
  if (vehicleFinancingTerm) schema.fields.term = schema.fields.term.max(vehicleFinancingTerm)

  return schema
}

const resolveMaxTerm = (schema: NumberSchema<number | undefined>, values: ProductsInsurance[]) => {
  const terms: number[] = []

  values.forEach((e) => {
    if (e) if (e.term) terms.push(e.term)
  })

  const max = min(terms)

  if (max) {
    return schema.max(max)
  }

  return schema
}

export const productsWorksheetSchema = yup.default
  .object({
    creditApplicationId: yup.default.string(),
    amountRequested: yupExtInt.double.required('common.errors.required').positive(),
    includeInsurance: yup.default.boolean().default(false),
    addFastPayments: yup.default.boolean().default(false),
    fundConfirmationNote: yup.default.string().default('').nullable(),
    merchantPayments: yup.default.mixed<MerchantPayment[]>().default([]),
    paymentPlanId: yup.default.string().required(),
    deliveryOn: yup.default
      .string()
      .isValidDate()
      .default(new Date().toDateString())
      .nullable()
      .required('common.errors.required')
      .typeError('common.errors.required'),

    firstPaymentOn: yup.default
      .date()
      .default(() => {
        const date = new Date()
        date.setDate(date.getDate() + 2)
        return date
      })
      .required('common.errors.required')
      .typeError('common.errors.required'),
    term: (baseWorksheetSchema.fields.term as NumberSchema)
      .required()
      .when(
        ['extendedWarranty', 'replacementOrGapInsurance', 'creditInsurance'],
        (values: ProductsInsurance[], schema: NumberSchema<number | undefined>) => {
          return resolveMaxTerm(schema, values)
        },
      ),
    vehicleFinancingTerm: yupExtInt.integer.nullable().required('common.errors.required').min(MIN_TERM).max(240),
    extendedWarranty: baseInsuranceSchema
      .when('vehicleFinancingTerm', (vehicleFinancingTerm, schema: typeof baseInsuranceSchema) => {
        return resolveInsuranceMaxTerm(schema, vehicleFinancingTerm as number)
      })
      .nullable(),
    replacementOrGapInsurance: baseInsuranceSchema
      .when('vehicleFinancingTerm', (vehicleFinancingTerm, schema: typeof baseInsuranceSchema) => {
        return resolveInsuranceMaxTerm(schema, vehicleFinancingTerm as number)
      })
      .nullable(),
    creditInsurance: baseInsuranceSchema
      .when('vehicleFinancingTerm', (vehicleFinancingTerm, schema: typeof baseInsuranceSchema) => {
        return resolveInsuranceMaxTerm(schema, vehicleFinancingTerm as number)
      })
      .nullable(),
  })
  .concat(baseWorksheetSchema.omit(['firstPaymentOn', 'lenderFee']))

export const ProductsWorksheetSchema = yup.default.object({ ...productsWorksheetSchema.fields })

export type ProductsWorksheet = IFinanceBaseWorksheet & {
  creditApplicationId: string
  creditInsurance: ProductsInsurance
  replacementOrGapInsurance: ProductsInsurance
  extendedWarranty: ProductsInsurance
  vehicleFinancingTerm: number
}

export type ProductsWorksheetDto = InferType<typeof productsWorksheetSchema>

export type ProductsWorksheetRevision = IFinanceBaseWorksheetRevision

const ProductsWorksheetDecisionDtoSchema = IFinanceBaseWorksheetDecisionDTOSchema
export type ProductsWorksheetDecisionDTO = yup.default.InferType<typeof ProductsWorksheetDecisionDtoSchema>
