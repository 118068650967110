import {
  ProductsWorksheet,
  ProductsWorksheetDto,
  ProductsWorksheetRevision,
} from '@src/data/types/ProductsWorksheetSchema'
import { WorksheetDecision } from '@src/data/types'
import { ProductsComputeRequestDto } from '@src/data/types/ProductsComputeRequestDto'
import { ProductsComputeResultDto } from '@src/data/types/ProductsComputeResultDto'
import { makeCreate, makeGetListWithIdsFromApi, makeGetSingleFromApi, makeUpdate } from '../make-api'
import transformProductsWorksheetToApi from './products-worksheet-transform'

const baseUrl = '/Applications/products/'
const worksheetUrl = `${baseUrl}{creditApplicationId}/Worksheet`
const calculatorUrl = `${baseUrl}Calculator/Compute`
const productsWorksheetApi = {
  getById: makeGetSingleFromApi<ProductsWorksheet>(worksheetUrl),
  create: makeCreate<ProductsWorksheetDto, ProductsWorksheet>(worksheetUrl),
  update: makeUpdate<ProductsWorksheetDto, ProductsWorksheet>(worksheetUrl, transformProductsWorksheetToApi),
  computeProductsLoan: makeCreate<ProductsComputeRequestDto, ProductsComputeResultDto>(calculatorUrl),
  getWorksheetRevisions: makeGetListWithIdsFromApi<ProductsWorksheetRevision>(`${worksheetUrl}/Revisions`),
  updateDecision: makeUpdate<WorksheetDecision, ProductsWorksheet>(`${worksheetUrl}/Decision`),
}

export default productsWorksheetApi
